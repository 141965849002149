/* eslint-disable */
// source: common_proto.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.Accessory', null, global);
goog.exportSymbol('proto.Account', null, global);
goog.exportSymbol('proto.AccountRole', null, global);
goog.exportSymbol('proto.ActiveLamp', null, global);
goog.exportSymbol('proto.CPUParameter', null, global);
goog.exportSymbol('proto.CPUScheduler', null, global);
goog.exportSymbol('proto.CardConfig', null, global);
goog.exportSymbol('proto.ConnectionState', null, global);
goog.exportSymbol('proto.CycleLampForm', null, global);
goog.exportSymbol('proto.DayLampForm', null, global);
goog.exportSymbol('proto.DayTimeLine', null, global);
goog.exportSymbol('proto.Device', null, global);
goog.exportSymbol('proto.DeviceState', null, global);
goog.exportSymbol('proto.DeviceType', null, global);
goog.exportSymbol('proto.HMTime', null, global);
goog.exportSymbol('proto.LaneState', null, global);
goog.exportSymbol('proto.MTFCMainWorkState', null, global);
goog.exportSymbol('proto.MTFCPhaseGateConfig', null, global);
goog.exportSymbol('proto.MeterItem', null, global);
goog.exportSymbol('proto.OneCycleLampTime', null, global);
goog.exportSymbol('proto.OneSideLampTime', null, global);
goog.exportSymbol('proto.OneTimeline', null, global);
goog.exportSymbol('proto.PinOutProfile', null, global);
goog.exportSymbol('proto.PinSwitchProfile', null, global);
goog.exportSymbol('proto.SignParam', null, global);
goog.exportSymbol('proto.Station', null, global);
goog.exportSymbol('proto.StationLaneLightConfig', null, global);
goog.exportSymbol('proto.StatusCode', null, global);
goog.exportSymbol('proto.TimeLampForm', null, global);
goog.exportSymbol('proto.TrafficLightState', null, global);
goog.exportSymbol('proto.WalkingState', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Account = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Account, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Account.displayName = 'proto.Account';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CPUParameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CPUParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CPUParameter.displayName = 'proto.CPUParameter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CPUScheduler = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CPUScheduler, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CPUScheduler.displayName = 'proto.CPUScheduler';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Device = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Device, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Device.displayName = 'proto.Device';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SignParam = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SignParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SignParam.displayName = 'proto.SignParam';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StationLaneLightConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.StationLaneLightConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StationLaneLightConfig.displayName = 'proto.StationLaneLightConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MeterItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MeterItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MeterItem.displayName = 'proto.MeterItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Station = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Station.repeatedFields_, null);
};
goog.inherits(proto.Station, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Station.displayName = 'proto.Station';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Accessory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Accessory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Accessory.displayName = 'proto.Accessory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CardConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CardConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CardConfig.displayName = 'proto.CardConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MTFCPhaseGateConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.MTFCPhaseGateConfig.repeatedFields_, null);
};
goog.inherits(proto.MTFCPhaseGateConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MTFCPhaseGateConfig.displayName = 'proto.MTFCPhaseGateConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.HMTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.HMTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.HMTime.displayName = 'proto.HMTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ActiveLamp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ActiveLamp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ActiveLamp.displayName = 'proto.ActiveLamp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.OneSideLampTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.OneSideLampTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.OneSideLampTime.displayName = 'proto.OneSideLampTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.OneCycleLampTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.OneCycleLampTime.repeatedFields_, null);
};
goog.inherits(proto.OneCycleLampTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.OneCycleLampTime.displayName = 'proto.OneCycleLampTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CycleLampForm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.CycleLampForm.repeatedFields_, null);
};
goog.inherits(proto.CycleLampForm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CycleLampForm.displayName = 'proto.CycleLampForm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.OneTimeline = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.OneTimeline, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.OneTimeline.displayName = 'proto.OneTimeline';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DayTimeLine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.DayTimeLine.repeatedFields_, null);
};
goog.inherits(proto.DayTimeLine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DayTimeLine.displayName = 'proto.DayTimeLine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TimeLampForm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TimeLampForm.repeatedFields_, null);
};
goog.inherits(proto.TimeLampForm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TimeLampForm.displayName = 'proto.TimeLampForm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DayLampForm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.DayLampForm.repeatedFields_, null);
};
goog.inherits(proto.DayLampForm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DayLampForm.displayName = 'proto.DayLampForm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LaneState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LaneState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LaneState.displayName = 'proto.LaneState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.DeviceState.repeatedFields_, null);
};
goog.inherits(proto.DeviceState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceState.displayName = 'proto.DeviceState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StatusCode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.StatusCode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StatusCode.displayName = 'proto.StatusCode';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Account.prototype.toObject = function(opt_includeInstance) {
  return proto.Account.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Account} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Account.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    role: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createdate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    iscurrentaccount: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Account}
 */
proto.Account.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Account;
  return proto.Account.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Account} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Account}
 */
proto.Account.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {!proto.AccountRole} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedate(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIscurrentaccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Account.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Account.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Account} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Account.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCreatedate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIscurrentaccount();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string userId = 1;
 * @return {string}
 */
proto.Account.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Account} returns this
 */
proto.Account.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string userName = 2;
 * @return {string}
 */
proto.Account.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Account} returns this
 */
proto.Account.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AccountRole role = 3;
 * @return {!proto.AccountRole}
 */
proto.Account.prototype.getRole = function() {
  return /** @type {!proto.AccountRole} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.AccountRole} value
 * @return {!proto.Account} returns this
 */
proto.Account.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string createDate = 4;
 * @return {string}
 */
proto.Account.prototype.getCreatedate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Account} returns this
 */
proto.Account.prototype.setCreatedate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool isCurrentAccount = 5;
 * @return {boolean}
 */
proto.Account.prototype.getIscurrentaccount = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Account} returns this
 */
proto.Account.prototype.setIscurrentaccount = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CPUParameter.prototype.toObject = function(opt_includeInstance) {
  return proto.CPUParameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CPUParameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CPUParameter.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CPUParameter}
 */
proto.CPUParameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CPUParameter;
  return proto.CPUParameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CPUParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CPUParameter}
 */
proto.CPUParameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CPUParameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CPUParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CPUParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CPUParameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CPUScheduler.prototype.toObject = function(opt_includeInstance) {
  return proto.CPUScheduler.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CPUScheduler} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CPUScheduler.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CPUScheduler}
 */
proto.CPUScheduler.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CPUScheduler;
  return proto.CPUScheduler.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CPUScheduler} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CPUScheduler}
 */
proto.CPUScheduler.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CPUScheduler.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CPUScheduler.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CPUScheduler} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CPUScheduler.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Device.prototype.toObject = function(opt_includeInstance) {
  return proto.Device.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Device} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Device.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    devicetype: jspb.Message.getFieldWithDefault(msg, 2, ""),
    devicestate: (f = msg.getDevicestate()) && proto.DeviceState.toObject(includeInstance, f),
    stationname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    hardwareid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    numphase: jspb.Message.getFieldWithDefault(msg, 6, 0),
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    log: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Device}
 */
proto.Device.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Device;
  return proto.Device.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Device} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Device}
 */
proto.Device.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevicetype(value);
      break;
    case 3:
      var value = new proto.DeviceState;
      reader.readMessage(value,proto.DeviceState.deserializeBinaryFromReader);
      msg.setDevicestate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setHardwareid(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumphase(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLat(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLog(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Device.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Device.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Device} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Device.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDevicetype();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDevicestate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.DeviceState.serializeBinaryToWriter
    );
  }
  f = message.getStationname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getHardwareid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNumphase();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getLog();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.Device.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Device} returns this
 */
proto.Device.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string deviceType = 2;
 * @return {string}
 */
proto.Device.prototype.getDevicetype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Device} returns this
 */
proto.Device.prototype.setDevicetype = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DeviceState deviceState = 3;
 * @return {?proto.DeviceState}
 */
proto.Device.prototype.getDevicestate = function() {
  return /** @type{?proto.DeviceState} */ (
    jspb.Message.getWrapperField(this, proto.DeviceState, 3));
};


/**
 * @param {?proto.DeviceState|undefined} value
 * @return {!proto.Device} returns this
*/
proto.Device.prototype.setDevicestate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Device} returns this
 */
proto.Device.prototype.clearDevicestate = function() {
  return this.setDevicestate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Device.prototype.hasDevicestate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string stationName = 4;
 * @return {string}
 */
proto.Device.prototype.getStationname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Device} returns this
 */
proto.Device.prototype.setStationname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string hardwareId = 5;
 * @return {string}
 */
proto.Device.prototype.getHardwareid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.Device} returns this
 */
proto.Device.prototype.setHardwareid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 numPhase = 6;
 * @return {number}
 */
proto.Device.prototype.getNumphase = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.Device} returns this
 */
proto.Device.prototype.setNumphase = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional float lat = 7;
 * @return {number}
 */
proto.Device.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Device} returns this
 */
proto.Device.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float log = 8;
 * @return {number}
 */
proto.Device.prototype.getLog = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Device} returns this
 */
proto.Device.prototype.setLog = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SignParam.prototype.toObject = function(opt_includeInstance) {
  return proto.SignParam.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SignParam} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SignParam.toObject = function(includeInstance, msg) {
  var f, obj = {
    channel: jspb.Message.getFieldWithDefault(msg, 1, 0),
    x: jspb.Message.getFieldWithDefault(msg, 2, 0),
    y: jspb.Message.getFieldWithDefault(msg, 3, 0),
    text: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SignParam}
 */
proto.SignParam.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SignParam;
  return proto.SignParam.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SignParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SignParam}
 */
proto.SignParam.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChannel(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setX(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setY(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SignParam.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SignParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SignParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SignParam.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannel();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getX();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getY();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional uint32 channel = 1;
 * @return {number}
 */
proto.SignParam.prototype.getChannel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.SignParam} returns this
 */
proto.SignParam.prototype.setChannel = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 x = 2;
 * @return {number}
 */
proto.SignParam.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.SignParam} returns this
 */
proto.SignParam.prototype.setX = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 y = 3;
 * @return {number}
 */
proto.SignParam.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.SignParam} returns this
 */
proto.SignParam.prototype.setY = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string text = 4;
 * @return {string}
 */
proto.SignParam.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.SignParam} returns this
 */
proto.SignParam.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StationLaneLightConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.StationLaneLightConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StationLaneLightConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StationLaneLightConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    countdown: (f = msg.getCountdown()) && proto.SignParam.toObject(includeInstance, f),
    trafficlight: (f = msg.getTrafficlight()) && proto.SignParam.toObject(includeInstance, f),
    walking: (f = msg.getWalking()) && proto.SignParam.toObject(includeInstance, f),
    railway: (f = msg.getRailway()) && proto.SignParam.toObject(includeInstance, f),
    turnright: (f = msg.getTurnright()) && proto.SignParam.toObject(includeInstance, f),
    goahead: (f = msg.getGoahead()) && proto.SignParam.toObject(includeInstance, f),
    turnleft: (f = msg.getTurnleft()) && proto.SignParam.toObject(includeInstance, f),
    index: jspb.Message.getFieldWithDefault(msg, 8, 0),
    label: (f = msg.getLabel()) && proto.SignParam.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StationLaneLightConfig}
 */
proto.StationLaneLightConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StationLaneLightConfig;
  return proto.StationLaneLightConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StationLaneLightConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StationLaneLightConfig}
 */
proto.StationLaneLightConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.SignParam;
      reader.readMessage(value,proto.SignParam.deserializeBinaryFromReader);
      msg.setCountdown(value);
      break;
    case 2:
      var value = new proto.SignParam;
      reader.readMessage(value,proto.SignParam.deserializeBinaryFromReader);
      msg.setTrafficlight(value);
      break;
    case 3:
      var value = new proto.SignParam;
      reader.readMessage(value,proto.SignParam.deserializeBinaryFromReader);
      msg.setWalking(value);
      break;
    case 4:
      var value = new proto.SignParam;
      reader.readMessage(value,proto.SignParam.deserializeBinaryFromReader);
      msg.setRailway(value);
      break;
    case 5:
      var value = new proto.SignParam;
      reader.readMessage(value,proto.SignParam.deserializeBinaryFromReader);
      msg.setTurnright(value);
      break;
    case 6:
      var value = new proto.SignParam;
      reader.readMessage(value,proto.SignParam.deserializeBinaryFromReader);
      msg.setGoahead(value);
      break;
    case 7:
      var value = new proto.SignParam;
      reader.readMessage(value,proto.SignParam.deserializeBinaryFromReader);
      msg.setTurnleft(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIndex(value);
      break;
    case 9:
      var value = new proto.SignParam;
      reader.readMessage(value,proto.SignParam.deserializeBinaryFromReader);
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StationLaneLightConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StationLaneLightConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StationLaneLightConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StationLaneLightConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountdown();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.SignParam.serializeBinaryToWriter
    );
  }
  f = message.getTrafficlight();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.SignParam.serializeBinaryToWriter
    );
  }
  f = message.getWalking();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.SignParam.serializeBinaryToWriter
    );
  }
  f = message.getRailway();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.SignParam.serializeBinaryToWriter
    );
  }
  f = message.getTurnright();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.SignParam.serializeBinaryToWriter
    );
  }
  f = message.getGoahead();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.SignParam.serializeBinaryToWriter
    );
  }
  f = message.getTurnleft();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.SignParam.serializeBinaryToWriter
    );
  }
  f = message.getIndex();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getLabel();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.SignParam.serializeBinaryToWriter
    );
  }
};


/**
 * optional SignParam countdown = 1;
 * @return {?proto.SignParam}
 */
proto.StationLaneLightConfig.prototype.getCountdown = function() {
  return /** @type{?proto.SignParam} */ (
    jspb.Message.getWrapperField(this, proto.SignParam, 1));
};


/**
 * @param {?proto.SignParam|undefined} value
 * @return {!proto.StationLaneLightConfig} returns this
*/
proto.StationLaneLightConfig.prototype.setCountdown = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationLaneLightConfig} returns this
 */
proto.StationLaneLightConfig.prototype.clearCountdown = function() {
  return this.setCountdown(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationLaneLightConfig.prototype.hasCountdown = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SignParam trafficLight = 2;
 * @return {?proto.SignParam}
 */
proto.StationLaneLightConfig.prototype.getTrafficlight = function() {
  return /** @type{?proto.SignParam} */ (
    jspb.Message.getWrapperField(this, proto.SignParam, 2));
};


/**
 * @param {?proto.SignParam|undefined} value
 * @return {!proto.StationLaneLightConfig} returns this
*/
proto.StationLaneLightConfig.prototype.setTrafficlight = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationLaneLightConfig} returns this
 */
proto.StationLaneLightConfig.prototype.clearTrafficlight = function() {
  return this.setTrafficlight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationLaneLightConfig.prototype.hasTrafficlight = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SignParam walking = 3;
 * @return {?proto.SignParam}
 */
proto.StationLaneLightConfig.prototype.getWalking = function() {
  return /** @type{?proto.SignParam} */ (
    jspb.Message.getWrapperField(this, proto.SignParam, 3));
};


/**
 * @param {?proto.SignParam|undefined} value
 * @return {!proto.StationLaneLightConfig} returns this
*/
proto.StationLaneLightConfig.prototype.setWalking = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationLaneLightConfig} returns this
 */
proto.StationLaneLightConfig.prototype.clearWalking = function() {
  return this.setWalking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationLaneLightConfig.prototype.hasWalking = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SignParam railway = 4;
 * @return {?proto.SignParam}
 */
proto.StationLaneLightConfig.prototype.getRailway = function() {
  return /** @type{?proto.SignParam} */ (
    jspb.Message.getWrapperField(this, proto.SignParam, 4));
};


/**
 * @param {?proto.SignParam|undefined} value
 * @return {!proto.StationLaneLightConfig} returns this
*/
proto.StationLaneLightConfig.prototype.setRailway = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationLaneLightConfig} returns this
 */
proto.StationLaneLightConfig.prototype.clearRailway = function() {
  return this.setRailway(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationLaneLightConfig.prototype.hasRailway = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SignParam turnRight = 5;
 * @return {?proto.SignParam}
 */
proto.StationLaneLightConfig.prototype.getTurnright = function() {
  return /** @type{?proto.SignParam} */ (
    jspb.Message.getWrapperField(this, proto.SignParam, 5));
};


/**
 * @param {?proto.SignParam|undefined} value
 * @return {!proto.StationLaneLightConfig} returns this
*/
proto.StationLaneLightConfig.prototype.setTurnright = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationLaneLightConfig} returns this
 */
proto.StationLaneLightConfig.prototype.clearTurnright = function() {
  return this.setTurnright(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationLaneLightConfig.prototype.hasTurnright = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional SignParam goAhead = 6;
 * @return {?proto.SignParam}
 */
proto.StationLaneLightConfig.prototype.getGoahead = function() {
  return /** @type{?proto.SignParam} */ (
    jspb.Message.getWrapperField(this, proto.SignParam, 6));
};


/**
 * @param {?proto.SignParam|undefined} value
 * @return {!proto.StationLaneLightConfig} returns this
*/
proto.StationLaneLightConfig.prototype.setGoahead = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationLaneLightConfig} returns this
 */
proto.StationLaneLightConfig.prototype.clearGoahead = function() {
  return this.setGoahead(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationLaneLightConfig.prototype.hasGoahead = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional SignParam turnLeft = 7;
 * @return {?proto.SignParam}
 */
proto.StationLaneLightConfig.prototype.getTurnleft = function() {
  return /** @type{?proto.SignParam} */ (
    jspb.Message.getWrapperField(this, proto.SignParam, 7));
};


/**
 * @param {?proto.SignParam|undefined} value
 * @return {!proto.StationLaneLightConfig} returns this
*/
proto.StationLaneLightConfig.prototype.setTurnleft = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationLaneLightConfig} returns this
 */
proto.StationLaneLightConfig.prototype.clearTurnleft = function() {
  return this.setTurnleft(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationLaneLightConfig.prototype.hasTurnleft = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional uint32 index = 8;
 * @return {number}
 */
proto.StationLaneLightConfig.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.StationLaneLightConfig} returns this
 */
proto.StationLaneLightConfig.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional SignParam label = 9;
 * @return {?proto.SignParam}
 */
proto.StationLaneLightConfig.prototype.getLabel = function() {
  return /** @type{?proto.SignParam} */ (
    jspb.Message.getWrapperField(this, proto.SignParam, 9));
};


/**
 * @param {?proto.SignParam|undefined} value
 * @return {!proto.StationLaneLightConfig} returns this
*/
proto.StationLaneLightConfig.prototype.setLabel = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationLaneLightConfig} returns this
 */
proto.StationLaneLightConfig.prototype.clearLabel = function() {
  return this.setLabel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationLaneLightConfig.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MeterItem.prototype.toObject = function(opt_includeInstance) {
  return proto.MeterItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MeterItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MeterItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    index: jspb.Message.getFieldWithDefault(msg, 1, 0),
    time: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    label: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MeterItem}
 */
proto.MeterItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MeterItem;
  return proto.MeterItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MeterItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MeterItem}
 */
proto.MeterItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIndex(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setData(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MeterItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MeterItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MeterItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MeterItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndex();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTime();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional uint32 index = 1;
 * @return {number}
 */
proto.MeterItem.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.MeterItem} returns this
 */
proto.MeterItem.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string time = 2;
 * @return {string}
 */
proto.MeterItem.prototype.getTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.MeterItem} returns this
 */
proto.MeterItem.prototype.setTime = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double data = 3;
 * @return {number}
 */
proto.MeterItem.prototype.getData = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.MeterItem} returns this
 */
proto.MeterItem.prototype.setData = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string label = 4;
 * @return {string}
 */
proto.MeterItem.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.MeterItem} returns this
 */
proto.MeterItem.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Station.repeatedFields_ = [7,19];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Station.prototype.toObject = function(opt_includeInstance) {
  return proto.Station.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Station} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Station.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    device: (f = msg.getDevice()) && proto.Device.toObject(includeInstance, f),
    stationlat: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    stationlong: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    numphase: jspb.Message.getFieldWithDefault(msg, 6, 0),
    lanelightconfigList: jspb.Message.toObjectList(msg.getLanelightconfigList(),
    proto.StationLaneLightConfig.toObject, includeInstance),
    background: jspb.Message.getFieldWithDefault(msg, 8, ""),
    hardwareid: jspb.Message.getFieldWithDefault(msg, 9, ""),
    firmware: jspb.Message.getFieldWithDefault(msg, 10, ""),
    createdate: jspb.Message.getFieldWithDefault(msg, 11, ""),
    numcard: jspb.Message.getFieldWithDefault(msg, 12, 0),
    errorstatus: jspb.Message.getFieldWithDefault(msg, 13, 0),
    errorstatusnote: jspb.Message.getFieldWithDefault(msg, 14, ""),
    connectionstate: jspb.Message.getFieldWithDefault(msg, 15, 0),
    ison: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    activebegin: jspb.Message.getFieldWithDefault(msg, 17, ""),
    activeend: jspb.Message.getFieldWithDefault(msg, 18, ""),
    meteritemsList: jspb.Message.toObjectList(msg.getMeteritemsList(),
    proto.MeterItem.toObject, includeInstance),
    deviceid: jspb.Message.getFieldWithDefault(msg, 20, ""),
    mode: jspb.Message.getFieldWithDefault(msg, 21, 0),
    v1: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
    v2: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
    v3: jspb.Message.getFloatingPointFieldWithDefault(msg, 24, 0.0),
    i1: jspb.Message.getFloatingPointFieldWithDefault(msg, 25, 0.0),
    i2: jspb.Message.getFloatingPointFieldWithDefault(msg, 26, 0.0),
    i3: jspb.Message.getFloatingPointFieldWithDefault(msg, 27, 0.0),
    p1: jspb.Message.getFloatingPointFieldWithDefault(msg, 28, 0.0),
    p2: jspb.Message.getFloatingPointFieldWithDefault(msg, 29, 0.0),
    p3: jspb.Message.getFloatingPointFieldWithDefault(msg, 30, 0.0),
    a1: jspb.Message.getFloatingPointFieldWithDefault(msg, 31, 0.0),
    a2: jspb.Message.getFloatingPointFieldWithDefault(msg, 32, 0.0),
    a3: jspb.Message.getFloatingPointFieldWithDefault(msg, 33, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Station}
 */
proto.Station.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Station;
  return proto.Station.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Station} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Station}
 */
proto.Station.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.Device;
      reader.readMessage(value,proto.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setStationlat(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setStationlong(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumphase(value);
      break;
    case 7:
      var value = new proto.StationLaneLightConfig;
      reader.readMessage(value,proto.StationLaneLightConfig.deserializeBinaryFromReader);
      msg.addLanelightconfig(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackground(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setHardwareid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirmware(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedate(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumcard(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setErrorstatus(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorstatusnote(value);
      break;
    case 15:
      var value = /** @type {!proto.ConnectionState} */ (reader.readEnum());
      msg.setConnectionstate(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIson(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivebegin(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setActiveend(value);
      break;
    case 19:
      var value = new proto.MeterItem;
      reader.readMessage(value,proto.MeterItem.deserializeBinaryFromReader);
      msg.addMeteritems(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMode(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setV1(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setV2(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setV3(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setI1(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setI2(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setI3(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setP1(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setP2(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setP3(value);
      break;
    case 31:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setA1(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setA2(value);
      break;
    case 33:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setA3(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Station.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Station.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Station} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Station.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.Device.serializeBinaryToWriter
    );
  }
  f = message.getStationlat();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getStationlong();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getNumphase();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getLanelightconfigList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.StationLaneLightConfig.serializeBinaryToWriter
    );
  }
  f = message.getBackground();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getHardwareid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getFirmware();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCreatedate();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getNumcard();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getErrorstatus();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
  f = message.getErrorstatusnote();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getConnectionstate();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getIson();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getActivebegin();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getActiveend();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getMeteritemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.MeterItem.serializeBinaryToWriter
    );
  }
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getMode();
  if (f !== 0) {
    writer.writeUint32(
      21,
      f
    );
  }
  f = message.getV1();
  if (f !== 0.0) {
    writer.writeDouble(
      22,
      f
    );
  }
  f = message.getV2();
  if (f !== 0.0) {
    writer.writeDouble(
      23,
      f
    );
  }
  f = message.getV3();
  if (f !== 0.0) {
    writer.writeDouble(
      24,
      f
    );
  }
  f = message.getI1();
  if (f !== 0.0) {
    writer.writeDouble(
      25,
      f
    );
  }
  f = message.getI2();
  if (f !== 0.0) {
    writer.writeDouble(
      26,
      f
    );
  }
  f = message.getI3();
  if (f !== 0.0) {
    writer.writeDouble(
      27,
      f
    );
  }
  f = message.getP1();
  if (f !== 0.0) {
    writer.writeDouble(
      28,
      f
    );
  }
  f = message.getP2();
  if (f !== 0.0) {
    writer.writeDouble(
      29,
      f
    );
  }
  f = message.getP3();
  if (f !== 0.0) {
    writer.writeDouble(
      30,
      f
    );
  }
  f = message.getA1();
  if (f !== 0.0) {
    writer.writeDouble(
      31,
      f
    );
  }
  f = message.getA2();
  if (f !== 0.0) {
    writer.writeDouble(
      32,
      f
    );
  }
  f = message.getA3();
  if (f !== 0.0) {
    writer.writeDouble(
      33,
      f
    );
  }
};


/**
 * optional string stationId = 1;
 * @return {string}
 */
proto.Station.prototype.getStationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setStationid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.Station.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Device device = 3;
 * @return {?proto.Device}
 */
proto.Station.prototype.getDevice = function() {
  return /** @type{?proto.Device} */ (
    jspb.Message.getWrapperField(this, proto.Device, 3));
};


/**
 * @param {?proto.Device|undefined} value
 * @return {!proto.Station} returns this
*/
proto.Station.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Station.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional float stationLat = 4;
 * @return {number}
 */
proto.Station.prototype.getStationlat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setStationlat = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float stationLong = 5;
 * @return {number}
 */
proto.Station.prototype.getStationlong = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setStationlong = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional uint32 numPhase = 6;
 * @return {number}
 */
proto.Station.prototype.getNumphase = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setNumphase = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated StationLaneLightConfig laneLightConfig = 7;
 * @return {!Array<!proto.StationLaneLightConfig>}
 */
proto.Station.prototype.getLanelightconfigList = function() {
  return /** @type{!Array<!proto.StationLaneLightConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.StationLaneLightConfig, 7));
};


/**
 * @param {!Array<!proto.StationLaneLightConfig>} value
 * @return {!proto.Station} returns this
*/
proto.Station.prototype.setLanelightconfigList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.StationLaneLightConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.StationLaneLightConfig}
 */
proto.Station.prototype.addLanelightconfig = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.StationLaneLightConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.clearLanelightconfigList = function() {
  return this.setLanelightconfigList([]);
};


/**
 * optional string background = 8;
 * @return {string}
 */
proto.Station.prototype.getBackground = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setBackground = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string hardwareId = 9;
 * @return {string}
 */
proto.Station.prototype.getHardwareid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setHardwareid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string firmware = 10;
 * @return {string}
 */
proto.Station.prototype.getFirmware = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setFirmware = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string createDate = 11;
 * @return {string}
 */
proto.Station.prototype.getCreatedate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setCreatedate = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional uint32 numCard = 12;
 * @return {number}
 */
proto.Station.prototype.getNumcard = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setNumcard = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional uint32 errorStatus = 13;
 * @return {number}
 */
proto.Station.prototype.getErrorstatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setErrorstatus = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string errorStatusNote = 14;
 * @return {string}
 */
proto.Station.prototype.getErrorstatusnote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setErrorstatusnote = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional ConnectionState connectionState = 15;
 * @return {!proto.ConnectionState}
 */
proto.Station.prototype.getConnectionstate = function() {
  return /** @type {!proto.ConnectionState} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.ConnectionState} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setConnectionstate = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * optional bool isOn = 16;
 * @return {boolean}
 */
proto.Station.prototype.getIson = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setIson = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional string activeBegin = 17;
 * @return {string}
 */
proto.Station.prototype.getActivebegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setActivebegin = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string activeEnd = 18;
 * @return {string}
 */
proto.Station.prototype.getActiveend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setActiveend = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * repeated MeterItem meterItems = 19;
 * @return {!Array<!proto.MeterItem>}
 */
proto.Station.prototype.getMeteritemsList = function() {
  return /** @type{!Array<!proto.MeterItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.MeterItem, 19));
};


/**
 * @param {!Array<!proto.MeterItem>} value
 * @return {!proto.Station} returns this
*/
proto.Station.prototype.setMeteritemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.MeterItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.MeterItem}
 */
proto.Station.prototype.addMeteritems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.MeterItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.clearMeteritemsList = function() {
  return this.setMeteritemsList([]);
};


/**
 * optional string deviceId = 20;
 * @return {string}
 */
proto.Station.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional uint32 mode = 21;
 * @return {number}
 */
proto.Station.prototype.getMode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setMode = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional double v1 = 22;
 * @return {number}
 */
proto.Station.prototype.getV1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setV1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional double v2 = 23;
 * @return {number}
 */
proto.Station.prototype.getV2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setV2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 23, value);
};


/**
 * optional double v3 = 24;
 * @return {number}
 */
proto.Station.prototype.getV3 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 24, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setV3 = function(value) {
  return jspb.Message.setProto3FloatField(this, 24, value);
};


/**
 * optional double i1 = 25;
 * @return {number}
 */
proto.Station.prototype.getI1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 25, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setI1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 25, value);
};


/**
 * optional double i2 = 26;
 * @return {number}
 */
proto.Station.prototype.getI2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 26, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setI2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 26, value);
};


/**
 * optional double i3 = 27;
 * @return {number}
 */
proto.Station.prototype.getI3 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 27, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setI3 = function(value) {
  return jspb.Message.setProto3FloatField(this, 27, value);
};


/**
 * optional double p1 = 28;
 * @return {number}
 */
proto.Station.prototype.getP1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 28, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setP1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 28, value);
};


/**
 * optional double p2 = 29;
 * @return {number}
 */
proto.Station.prototype.getP2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 29, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setP2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 29, value);
};


/**
 * optional double p3 = 30;
 * @return {number}
 */
proto.Station.prototype.getP3 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 30, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setP3 = function(value) {
  return jspb.Message.setProto3FloatField(this, 30, value);
};


/**
 * optional double a1 = 31;
 * @return {number}
 */
proto.Station.prototype.getA1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 31, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setA1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 31, value);
};


/**
 * optional double a2 = 32;
 * @return {number}
 */
proto.Station.prototype.getA2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 32, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setA2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 32, value);
};


/**
 * optional double a3 = 33;
 * @return {number}
 */
proto.Station.prototype.getA3 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 33, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Station} returns this
 */
proto.Station.prototype.setA3 = function(value) {
  return jspb.Message.setProto3FloatField(this, 33, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Accessory.prototype.toObject = function(opt_includeInstance) {
  return proto.Accessory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Accessory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Accessory.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    model: jspb.Message.getFieldWithDefault(msg, 3, ""),
    madeby: jspb.Message.getFieldWithDefault(msg, 4, ""),
    position: jspb.Message.getFieldWithDefault(msg, 5, ""),
    installedpos: jspb.Message.getFieldWithDefault(msg, 6, ""),
    installeddate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    status: jspb.Message.getFieldWithDefault(msg, 8, ""),
    stationid: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Accessory}
 */
proto.Accessory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Accessory;
  return proto.Accessory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Accessory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Accessory}
 */
proto.Accessory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMadeby(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPosition(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstalledpos(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstalleddate(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Accessory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Accessory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Accessory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Accessory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMadeby();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPosition();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getInstalledpos();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getInstalleddate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStationid();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.Accessory.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Accessory} returns this
 */
proto.Accessory.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.Accessory.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Accessory} returns this
 */
proto.Accessory.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string model = 3;
 * @return {string}
 */
proto.Accessory.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Accessory} returns this
 */
proto.Accessory.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string madeBy = 4;
 * @return {string}
 */
proto.Accessory.prototype.getMadeby = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Accessory} returns this
 */
proto.Accessory.prototype.setMadeby = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string position = 5;
 * @return {string}
 */
proto.Accessory.prototype.getPosition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.Accessory} returns this
 */
proto.Accessory.prototype.setPosition = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string installedPos = 6;
 * @return {string}
 */
proto.Accessory.prototype.getInstalledpos = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.Accessory} returns this
 */
proto.Accessory.prototype.setInstalledpos = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string installedDate = 7;
 * @return {string}
 */
proto.Accessory.prototype.getInstalleddate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.Accessory} returns this
 */
proto.Accessory.prototype.setInstalleddate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string status = 8;
 * @return {string}
 */
proto.Accessory.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.Accessory} returns this
 */
proto.Accessory.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string stationId = 9;
 * @return {string}
 */
proto.Accessory.prototype.getStationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.Accessory} returns this
 */
proto.Accessory.prototype.setStationid = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CardConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.CardConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CardConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CardConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialnumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    imei: jspb.Message.getFieldWithDefault(msg, 2, 0),
    phase: jspb.Message.getFieldWithDefault(msg, 3, 0),
    israilwayenabled: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CardConfig}
 */
proto.CardConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CardConfig;
  return proto.CardConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CardConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CardConfig}
 */
proto.CardConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialnumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setImei(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPhase(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIsrailwayenabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CardConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CardConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CardConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CardConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialnumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getImei();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPhase();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getIsrailwayenabled();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional string serialNumber = 1;
 * @return {string}
 */
proto.CardConfig.prototype.getSerialnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.CardConfig} returns this
 */
proto.CardConfig.prototype.setSerialnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 imei = 2;
 * @return {number}
 */
proto.CardConfig.prototype.getImei = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.CardConfig} returns this
 */
proto.CardConfig.prototype.setImei = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 phase = 3;
 * @return {number}
 */
proto.CardConfig.prototype.getPhase = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.CardConfig} returns this
 */
proto.CardConfig.prototype.setPhase = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 isRailwayEnabled = 4;
 * @return {number}
 */
proto.CardConfig.prototype.getIsrailwayenabled = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.CardConfig} returns this
 */
proto.CardConfig.prototype.setIsrailwayenabled = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.MTFCPhaseGateConfig.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MTFCPhaseGateConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.MTFCPhaseGateConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MTFCPhaseGateConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MTFCPhaseGateConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    pinList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MTFCPhaseGateConfig}
 */
proto.MTFCPhaseGateConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MTFCPhaseGateConfig;
  return proto.MTFCPhaseGateConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MTFCPhaseGateConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MTFCPhaseGateConfig}
 */
proto.MTFCPhaseGateConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint32());
      msg.setPinList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MTFCPhaseGateConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MTFCPhaseGateConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MTFCPhaseGateConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MTFCPhaseGateConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPinList();
  if (f.length > 0) {
    writer.writePackedUint32(
      1,
      f
    );
  }
};


/**
 * repeated uint32 pin = 1;
 * @return {!Array<number>}
 */
proto.MTFCPhaseGateConfig.prototype.getPinList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.MTFCPhaseGateConfig} returns this
 */
proto.MTFCPhaseGateConfig.prototype.setPinList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.MTFCPhaseGateConfig} returns this
 */
proto.MTFCPhaseGateConfig.prototype.addPin = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.MTFCPhaseGateConfig} returns this
 */
proto.MTFCPhaseGateConfig.prototype.clearPinList = function() {
  return this.setPinList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.HMTime.prototype.toObject = function(opt_includeInstance) {
  return proto.HMTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.HMTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.HMTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    hour: jspb.Message.getFieldWithDefault(msg, 1, 0),
    minute: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.HMTime}
 */
proto.HMTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.HMTime;
  return proto.HMTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.HMTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.HMTime}
 */
proto.HMTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setHour(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinute(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.HMTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.HMTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.HMTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.HMTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHour();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getMinute();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 hour = 1;
 * @return {number}
 */
proto.HMTime.prototype.getHour = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.HMTime} returns this
 */
proto.HMTime.prototype.setHour = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 minute = 2;
 * @return {number}
 */
proto.HMTime.prototype.getMinute = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.HMTime} returns this
 */
proto.HMTime.prototype.setMinute = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ActiveLamp.prototype.toObject = function(opt_includeInstance) {
  return proto.ActiveLamp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ActiveLamp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ActiveLamp.toObject = function(includeInstance, msg) {
  var f, obj = {
    begin: (f = msg.getBegin()) && proto.HMTime.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && proto.HMTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ActiveLamp}
 */
proto.ActiveLamp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ActiveLamp;
  return proto.ActiveLamp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ActiveLamp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ActiveLamp}
 */
proto.ActiveLamp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.HMTime;
      reader.readMessage(value,proto.HMTime.deserializeBinaryFromReader);
      msg.setBegin(value);
      break;
    case 2:
      var value = new proto.HMTime;
      reader.readMessage(value,proto.HMTime.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ActiveLamp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ActiveLamp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ActiveLamp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ActiveLamp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBegin();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.HMTime.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.HMTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional HMTime begin = 1;
 * @return {?proto.HMTime}
 */
proto.ActiveLamp.prototype.getBegin = function() {
  return /** @type{?proto.HMTime} */ (
    jspb.Message.getWrapperField(this, proto.HMTime, 1));
};


/**
 * @param {?proto.HMTime|undefined} value
 * @return {!proto.ActiveLamp} returns this
*/
proto.ActiveLamp.prototype.setBegin = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ActiveLamp} returns this
 */
proto.ActiveLamp.prototype.clearBegin = function() {
  return this.setBegin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ActiveLamp.prototype.hasBegin = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional HMTime end = 2;
 * @return {?proto.HMTime}
 */
proto.ActiveLamp.prototype.getEnd = function() {
  return /** @type{?proto.HMTime} */ (
    jspb.Message.getWrapperField(this, proto.HMTime, 2));
};


/**
 * @param {?proto.HMTime|undefined} value
 * @return {!proto.ActiveLamp} returns this
*/
proto.ActiveLamp.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ActiveLamp} returns this
 */
proto.ActiveLamp.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ActiveLamp.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.OneSideLampTime.prototype.toObject = function(opt_includeInstance) {
  return proto.OneSideLampTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.OneSideLampTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OneSideLampTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    tGreen: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tYellow: jspb.Message.getFieldWithDefault(msg, 2, 0),
    tRed: jspb.Message.getFieldWithDefault(msg, 3, 0),
    tStartGreen: jspb.Message.getFieldWithDefault(msg, 4, 0),
    tEndGreen: jspb.Message.getFieldWithDefault(msg, 5, 0),
    tStartYellow: jspb.Message.getFieldWithDefault(msg, 6, 0),
    tEndYellow: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.OneSideLampTime}
 */
proto.OneSideLampTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.OneSideLampTime;
  return proto.OneSideLampTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.OneSideLampTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.OneSideLampTime}
 */
proto.OneSideLampTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTGreen(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTYellow(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTRed(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTStartGreen(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTEndGreen(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTStartYellow(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTEndYellow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.OneSideLampTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.OneSideLampTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.OneSideLampTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OneSideLampTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTGreen();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTYellow();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTRed();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getTStartGreen();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getTEndGreen();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTStartYellow();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getTEndYellow();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
};


/**
 * optional uint32 t_green = 1;
 * @return {number}
 */
proto.OneSideLampTime.prototype.getTGreen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.OneSideLampTime} returns this
 */
proto.OneSideLampTime.prototype.setTGreen = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 t_yellow = 2;
 * @return {number}
 */
proto.OneSideLampTime.prototype.getTYellow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.OneSideLampTime} returns this
 */
proto.OneSideLampTime.prototype.setTYellow = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 t_red = 3;
 * @return {number}
 */
proto.OneSideLampTime.prototype.getTRed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.OneSideLampTime} returns this
 */
proto.OneSideLampTime.prototype.setTRed = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 t_start_green = 4;
 * @return {number}
 */
proto.OneSideLampTime.prototype.getTStartGreen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.OneSideLampTime} returns this
 */
proto.OneSideLampTime.prototype.setTStartGreen = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 t_end_green = 5;
 * @return {number}
 */
proto.OneSideLampTime.prototype.getTEndGreen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.OneSideLampTime} returns this
 */
proto.OneSideLampTime.prototype.setTEndGreen = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 t_start_yellow = 6;
 * @return {number}
 */
proto.OneSideLampTime.prototype.getTStartYellow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.OneSideLampTime} returns this
 */
proto.OneSideLampTime.prototype.setTStartYellow = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 t_end_yellow = 7;
 * @return {number}
 */
proto.OneSideLampTime.prototype.getTEndYellow = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.OneSideLampTime} returns this
 */
proto.OneSideLampTime.prototype.setTEndYellow = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.OneCycleLampTime.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.OneCycleLampTime.prototype.toObject = function(opt_includeInstance) {
  return proto.OneCycleLampTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.OneCycleLampTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OneCycleLampTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    numSideUsed: jspb.Message.getFieldWithDefault(msg, 1, 0),
    periodCrossroads: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sideList: jspb.Message.toObjectList(msg.getSideList(),
    proto.OneSideLampTime.toObject, includeInstance),
    clearanceTimeCrossroads: jspb.Message.getFieldWithDefault(msg, 4, 0),
    ismodified: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.OneCycleLampTime}
 */
proto.OneCycleLampTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.OneCycleLampTime;
  return proto.OneCycleLampTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.OneCycleLampTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.OneCycleLampTime}
 */
proto.OneCycleLampTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumSideUsed(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPeriodCrossroads(value);
      break;
    case 3:
      var value = new proto.OneSideLampTime;
      reader.readMessage(value,proto.OneSideLampTime.deserializeBinaryFromReader);
      msg.addSide(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setClearanceTimeCrossroads(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsmodified(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.OneCycleLampTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.OneCycleLampTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.OneCycleLampTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OneCycleLampTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumSideUsed();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPeriodCrossroads();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSideList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.OneSideLampTime.serializeBinaryToWriter
    );
  }
  f = message.getClearanceTimeCrossroads();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getIsmodified();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional uint32 num_side_used = 1;
 * @return {number}
 */
proto.OneCycleLampTime.prototype.getNumSideUsed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.OneCycleLampTime} returns this
 */
proto.OneCycleLampTime.prototype.setNumSideUsed = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 period_crossroads = 2;
 * @return {number}
 */
proto.OneCycleLampTime.prototype.getPeriodCrossroads = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.OneCycleLampTime} returns this
 */
proto.OneCycleLampTime.prototype.setPeriodCrossroads = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated OneSideLampTime side = 3;
 * @return {!Array<!proto.OneSideLampTime>}
 */
proto.OneCycleLampTime.prototype.getSideList = function() {
  return /** @type{!Array<!proto.OneSideLampTime>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.OneSideLampTime, 3));
};


/**
 * @param {!Array<!proto.OneSideLampTime>} value
 * @return {!proto.OneCycleLampTime} returns this
*/
proto.OneCycleLampTime.prototype.setSideList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.OneSideLampTime=} opt_value
 * @param {number=} opt_index
 * @return {!proto.OneSideLampTime}
 */
proto.OneCycleLampTime.prototype.addSide = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.OneSideLampTime, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.OneCycleLampTime} returns this
 */
proto.OneCycleLampTime.prototype.clearSideList = function() {
  return this.setSideList([]);
};


/**
 * optional uint32 clearance_time_crossroads = 4;
 * @return {number}
 */
proto.OneCycleLampTime.prototype.getClearanceTimeCrossroads = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.OneCycleLampTime} returns this
 */
proto.OneCycleLampTime.prototype.setClearanceTimeCrossroads = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool isModified = 5;
 * @return {boolean}
 */
proto.OneCycleLampTime.prototype.getIsmodified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.OneCycleLampTime} returns this
 */
proto.OneCycleLampTime.prototype.setIsmodified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.CycleLampForm.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CycleLampForm.prototype.toObject = function(opt_includeInstance) {
  return proto.CycleLampForm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CycleLampForm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CycleLampForm.toObject = function(includeInstance, msg) {
  var f, obj = {
    indexList: jspb.Message.toObjectList(msg.getIndexList(),
    proto.OneCycleLampTime.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CycleLampForm}
 */
proto.CycleLampForm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CycleLampForm;
  return proto.CycleLampForm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CycleLampForm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CycleLampForm}
 */
proto.CycleLampForm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.OneCycleLampTime;
      reader.readMessage(value,proto.OneCycleLampTime.deserializeBinaryFromReader);
      msg.addIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CycleLampForm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CycleLampForm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CycleLampForm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CycleLampForm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndexList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.OneCycleLampTime.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OneCycleLampTime index = 1;
 * @return {!Array<!proto.OneCycleLampTime>}
 */
proto.CycleLampForm.prototype.getIndexList = function() {
  return /** @type{!Array<!proto.OneCycleLampTime>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.OneCycleLampTime, 1));
};


/**
 * @param {!Array<!proto.OneCycleLampTime>} value
 * @return {!proto.CycleLampForm} returns this
*/
proto.CycleLampForm.prototype.setIndexList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.OneCycleLampTime=} opt_value
 * @param {number=} opt_index
 * @return {!proto.OneCycleLampTime}
 */
proto.CycleLampForm.prototype.addIndex = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.OneCycleLampTime, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.CycleLampForm} returns this
 */
proto.CycleLampForm.prototype.clearIndexList = function() {
  return this.setIndexList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.OneTimeline.prototype.toObject = function(opt_includeInstance) {
  return proto.OneTimeline.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.OneTimeline} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OneTimeline.toObject = function(includeInstance, msg) {
  var f, obj = {
    tBeginApply: (f = msg.getTBeginApply()) && proto.HMTime.toObject(includeInstance, f),
    indexCycleForm: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.OneTimeline}
 */
proto.OneTimeline.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.OneTimeline;
  return proto.OneTimeline.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.OneTimeline} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.OneTimeline}
 */
proto.OneTimeline.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.HMTime;
      reader.readMessage(value,proto.HMTime.deserializeBinaryFromReader);
      msg.setTBeginApply(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIndexCycleForm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.OneTimeline.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.OneTimeline.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.OneTimeline} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OneTimeline.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTBeginApply();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.HMTime.serializeBinaryToWriter
    );
  }
  f = message.getIndexCycleForm();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional HMTime t_begin_apply = 1;
 * @return {?proto.HMTime}
 */
proto.OneTimeline.prototype.getTBeginApply = function() {
  return /** @type{?proto.HMTime} */ (
    jspb.Message.getWrapperField(this, proto.HMTime, 1));
};


/**
 * @param {?proto.HMTime|undefined} value
 * @return {!proto.OneTimeline} returns this
*/
proto.OneTimeline.prototype.setTBeginApply = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.OneTimeline} returns this
 */
proto.OneTimeline.prototype.clearTBeginApply = function() {
  return this.setTBeginApply(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.OneTimeline.prototype.hasTBeginApply = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 index_cycle_form = 2;
 * @return {number}
 */
proto.OneTimeline.prototype.getIndexCycleForm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.OneTimeline} returns this
 */
proto.OneTimeline.prototype.setIndexCycleForm = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.DayTimeLine.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DayTimeLine.prototype.toObject = function(opt_includeInstance) {
  return proto.DayTimeLine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DayTimeLine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DayTimeLine.toObject = function(includeInstance, msg) {
  var f, obj = {
    selectpointList: jspb.Message.toObjectList(msg.getSelectpointList(),
    proto.OneTimeline.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DayTimeLine}
 */
proto.DayTimeLine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DayTimeLine;
  return proto.DayTimeLine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DayTimeLine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DayTimeLine}
 */
proto.DayTimeLine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.OneTimeline;
      reader.readMessage(value,proto.OneTimeline.deserializeBinaryFromReader);
      msg.addSelectpoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DayTimeLine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DayTimeLine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DayTimeLine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DayTimeLine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSelectpointList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.OneTimeline.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OneTimeline selectPoint = 1;
 * @return {!Array<!proto.OneTimeline>}
 */
proto.DayTimeLine.prototype.getSelectpointList = function() {
  return /** @type{!Array<!proto.OneTimeline>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.OneTimeline, 1));
};


/**
 * @param {!Array<!proto.OneTimeline>} value
 * @return {!proto.DayTimeLine} returns this
*/
proto.DayTimeLine.prototype.setSelectpointList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.OneTimeline=} opt_value
 * @param {number=} opt_index
 * @return {!proto.OneTimeline}
 */
proto.DayTimeLine.prototype.addSelectpoint = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.OneTimeline, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.DayTimeLine} returns this
 */
proto.DayTimeLine.prototype.clearSelectpointList = function() {
  return this.setSelectpointList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TimeLampForm.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TimeLampForm.prototype.toObject = function(opt_includeInstance) {
  return proto.TimeLampForm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TimeLampForm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimeLampForm.toObject = function(includeInstance, msg) {
  var f, obj = {
    indexList: jspb.Message.toObjectList(msg.getIndexList(),
    proto.DayTimeLine.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TimeLampForm}
 */
proto.TimeLampForm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TimeLampForm;
  return proto.TimeLampForm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TimeLampForm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TimeLampForm}
 */
proto.TimeLampForm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DayTimeLine;
      reader.readMessage(value,proto.DayTimeLine.deserializeBinaryFromReader);
      msg.addIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TimeLampForm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TimeLampForm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TimeLampForm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TimeLampForm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndexList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.DayTimeLine.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DayTimeLine index = 1;
 * @return {!Array<!proto.DayTimeLine>}
 */
proto.TimeLampForm.prototype.getIndexList = function() {
  return /** @type{!Array<!proto.DayTimeLine>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.DayTimeLine, 1));
};


/**
 * @param {!Array<!proto.DayTimeLine>} value
 * @return {!proto.TimeLampForm} returns this
*/
proto.TimeLampForm.prototype.setIndexList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.DayTimeLine=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DayTimeLine}
 */
proto.TimeLampForm.prototype.addIndex = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.DayTimeLine, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TimeLampForm} returns this
 */
proto.TimeLampForm.prototype.clearIndexList = function() {
  return this.setIndexList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.DayLampForm.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DayLampForm.prototype.toObject = function(opt_includeInstance) {
  return proto.DayLampForm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DayLampForm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DayLampForm.toObject = function(includeInstance, msg) {
  var f, obj = {
    indexList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DayLampForm}
 */
proto.DayLampForm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DayLampForm;
  return proto.DayLampForm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DayLampForm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DayLampForm}
 */
proto.DayLampForm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint32());
      msg.setIndexList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DayLampForm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DayLampForm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DayLampForm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DayLampForm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndexList();
  if (f.length > 0) {
    writer.writePackedUint32(
      1,
      f
    );
  }
};


/**
 * repeated uint32 index = 1;
 * @return {!Array<number>}
 */
proto.DayLampForm.prototype.getIndexList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.DayLampForm} returns this
 */
proto.DayLampForm.prototype.setIndexList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.DayLampForm} returns this
 */
proto.DayLampForm.prototype.addIndex = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.DayLampForm} returns this
 */
proto.DayLampForm.prototype.clearIndexList = function() {
  return this.setIndexList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LaneState.prototype.toObject = function(opt_includeInstance) {
  return proto.LaneState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LaneState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LaneState.toObject = function(includeInstance, msg) {
  var f, obj = {
    walkingstate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    lightstate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    counter: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LaneState}
 */
proto.LaneState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LaneState;
  return proto.LaneState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LaneState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LaneState}
 */
proto.LaneState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.WalkingState} */ (reader.readEnum());
      msg.setWalkingstate(value);
      break;
    case 2:
      var value = /** @type {!proto.TrafficLightState} */ (reader.readEnum());
      msg.setLightstate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCounter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LaneState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LaneState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LaneState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LaneState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWalkingstate();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getLightstate();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCounter();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional WalkingState walkingState = 1;
 * @return {!proto.WalkingState}
 */
proto.LaneState.prototype.getWalkingstate = function() {
  return /** @type {!proto.WalkingState} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.WalkingState} value
 * @return {!proto.LaneState} returns this
 */
proto.LaneState.prototype.setWalkingstate = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional TrafficLightState lightState = 2;
 * @return {!proto.TrafficLightState}
 */
proto.LaneState.prototype.getLightstate = function() {
  return /** @type {!proto.TrafficLightState} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.TrafficLightState} value
 * @return {!proto.LaneState} returns this
 */
proto.LaneState.prototype.setLightstate = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint32 counter = 3;
 * @return {number}
 */
proto.LaneState.prototype.getCounter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.LaneState} returns this
 */
proto.LaneState.prototype.setCounter = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.DeviceState.repeatedFields_ = [3,4,5,7,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceState.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceState.toObject = function(includeInstance, msg) {
  var f, obj = {
    connectionstate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    numPhase: jspb.Message.getFieldWithDefault(msg, 2, 0),
    signalList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    mappingList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    countdownList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    rtcEpoch: jspb.Message.getFieldWithDefault(msg, 6, 0),
    outputSensorList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    walkingSignal: jspb.Message.getFieldWithDefault(msg, 8, 0),
    railwaySignal: jspb.Message.getFieldWithDefault(msg, 9, 0),
    cpuActiveMode: jspb.Message.getFieldWithDefault(msg, 10, 0),
    inputHardware: jspb.Message.getFieldWithDefault(msg, 11, 0),
    inputSoftware: jspb.Message.getFieldWithDefault(msg, 12, 0),
    idxTimeForm: jspb.Message.getFieldWithDefault(msg, 13, 0),
    idxTimeline: jspb.Message.getFieldWithDefault(msg, 14, 0),
    idxCycleForm: jspb.Message.getFieldWithDefault(msg, 15, 0),
    lanestateList: jspb.Message.toObjectList(msg.getLanestateList(),
    proto.LaneState.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceState}
 */
proto.DeviceState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceState;
  return proto.DeviceState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceState}
 */
proto.DeviceState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ConnectionState} */ (reader.readEnum());
      msg.setConnectionstate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumPhase(value);
      break;
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint32());
      msg.setSignalList(value);
      break;
    case 4:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint32());
      msg.setMappingList(value);
      break;
    case 5:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint32());
      msg.setCountdownList(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRtcEpoch(value);
      break;
    case 7:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint32());
      msg.setOutputSensorList(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWalkingSignal(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRailwaySignal(value);
      break;
    case 10:
      var value = /** @type {!proto.MTFCMainWorkState} */ (reader.readEnum());
      msg.setCpuActiveMode(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setInputHardware(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setInputSoftware(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIdxTimeForm(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIdxTimeline(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIdxCycleForm(value);
      break;
    case 16:
      var value = new proto.LaneState;
      reader.readMessage(value,proto.LaneState.deserializeBinaryFromReader);
      msg.addLanestate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConnectionstate();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getNumPhase();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSignalList();
  if (f.length > 0) {
    writer.writePackedUint32(
      3,
      f
    );
  }
  f = message.getMappingList();
  if (f.length > 0) {
    writer.writePackedUint32(
      4,
      f
    );
  }
  f = message.getCountdownList();
  if (f.length > 0) {
    writer.writePackedUint32(
      5,
      f
    );
  }
  f = message.getRtcEpoch();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getOutputSensorList();
  if (f.length > 0) {
    writer.writePackedUint32(
      7,
      f
    );
  }
  f = message.getWalkingSignal();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getRailwaySignal();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getCpuActiveMode();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getInputHardware();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getInputSoftware();
  if (f !== 0) {
    writer.writeUint32(
      12,
      f
    );
  }
  f = message.getIdxTimeForm();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
  f = message.getIdxTimeline();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getIdxCycleForm();
  if (f !== 0) {
    writer.writeUint32(
      15,
      f
    );
  }
  f = message.getLanestateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.LaneState.serializeBinaryToWriter
    );
  }
};


/**
 * optional ConnectionState connectionState = 1;
 * @return {!proto.ConnectionState}
 */
proto.DeviceState.prototype.getConnectionstate = function() {
  return /** @type {!proto.ConnectionState} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.ConnectionState} value
 * @return {!proto.DeviceState} returns this
 */
proto.DeviceState.prototype.setConnectionstate = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 num_phase = 2;
 * @return {number}
 */
proto.DeviceState.prototype.getNumPhase = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.DeviceState} returns this
 */
proto.DeviceState.prototype.setNumPhase = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated uint32 signal = 3;
 * @return {!Array<number>}
 */
proto.DeviceState.prototype.getSignalList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.DeviceState} returns this
 */
proto.DeviceState.prototype.setSignalList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.DeviceState} returns this
 */
proto.DeviceState.prototype.addSignal = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.DeviceState} returns this
 */
proto.DeviceState.prototype.clearSignalList = function() {
  return this.setSignalList([]);
};


/**
 * repeated uint32 mapping = 4;
 * @return {!Array<number>}
 */
proto.DeviceState.prototype.getMappingList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.DeviceState} returns this
 */
proto.DeviceState.prototype.setMappingList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.DeviceState} returns this
 */
proto.DeviceState.prototype.addMapping = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.DeviceState} returns this
 */
proto.DeviceState.prototype.clearMappingList = function() {
  return this.setMappingList([]);
};


/**
 * repeated uint32 countdown = 5;
 * @return {!Array<number>}
 */
proto.DeviceState.prototype.getCountdownList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.DeviceState} returns this
 */
proto.DeviceState.prototype.setCountdownList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.DeviceState} returns this
 */
proto.DeviceState.prototype.addCountdown = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.DeviceState} returns this
 */
proto.DeviceState.prototype.clearCountdownList = function() {
  return this.setCountdownList([]);
};


/**
 * optional uint32 rtc_epoch = 6;
 * @return {number}
 */
proto.DeviceState.prototype.getRtcEpoch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.DeviceState} returns this
 */
proto.DeviceState.prototype.setRtcEpoch = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated uint32 output_sensor = 7;
 * @return {!Array<number>}
 */
proto.DeviceState.prototype.getOutputSensorList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.DeviceState} returns this
 */
proto.DeviceState.prototype.setOutputSensorList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.DeviceState} returns this
 */
proto.DeviceState.prototype.addOutputSensor = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.DeviceState} returns this
 */
proto.DeviceState.prototype.clearOutputSensorList = function() {
  return this.setOutputSensorList([]);
};


/**
 * optional uint32 walking_signal = 8;
 * @return {number}
 */
proto.DeviceState.prototype.getWalkingSignal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.DeviceState} returns this
 */
proto.DeviceState.prototype.setWalkingSignal = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 railway_signal = 9;
 * @return {number}
 */
proto.DeviceState.prototype.getRailwaySignal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.DeviceState} returns this
 */
proto.DeviceState.prototype.setRailwaySignal = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional MTFCMainWorkState cpu_active_mode = 10;
 * @return {!proto.MTFCMainWorkState}
 */
proto.DeviceState.prototype.getCpuActiveMode = function() {
  return /** @type {!proto.MTFCMainWorkState} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.MTFCMainWorkState} value
 * @return {!proto.DeviceState} returns this
 */
proto.DeviceState.prototype.setCpuActiveMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional uint32 input_hardware = 11;
 * @return {number}
 */
proto.DeviceState.prototype.getInputHardware = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.DeviceState} returns this
 */
proto.DeviceState.prototype.setInputHardware = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional uint32 input_software = 12;
 * @return {number}
 */
proto.DeviceState.prototype.getInputSoftware = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.DeviceState} returns this
 */
proto.DeviceState.prototype.setInputSoftware = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional uint32 idx_time_form = 13;
 * @return {number}
 */
proto.DeviceState.prototype.getIdxTimeForm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.DeviceState} returns this
 */
proto.DeviceState.prototype.setIdxTimeForm = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional uint32 idx_timeline = 14;
 * @return {number}
 */
proto.DeviceState.prototype.getIdxTimeline = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.DeviceState} returns this
 */
proto.DeviceState.prototype.setIdxTimeline = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional uint32 idx_cycle_form = 15;
 * @return {number}
 */
proto.DeviceState.prototype.getIdxCycleForm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.DeviceState} returns this
 */
proto.DeviceState.prototype.setIdxCycleForm = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * repeated LaneState laneState = 16;
 * @return {!Array<!proto.LaneState>}
 */
proto.DeviceState.prototype.getLanestateList = function() {
  return /** @type{!Array<!proto.LaneState>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.LaneState, 16));
};


/**
 * @param {!Array<!proto.LaneState>} value
 * @return {!proto.DeviceState} returns this
*/
proto.DeviceState.prototype.setLanestateList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.LaneState=} opt_value
 * @param {number=} opt_index
 * @return {!proto.LaneState}
 */
proto.DeviceState.prototype.addLanestate = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.LaneState, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.DeviceState} returns this
 */
proto.DeviceState.prototype.clearLanestateList = function() {
  return this.setLanestateList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StatusCode.prototype.toObject = function(opt_includeInstance) {
  return proto.StatusCode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StatusCode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StatusCode.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    code: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StatusCode}
 */
proto.StatusCode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StatusCode;
  return proto.StatusCode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StatusCode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StatusCode}
 */
proto.StatusCode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StatusCode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StatusCode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StatusCode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StatusCode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCode();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.StatusCode.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.StatusCode} returns this
 */
proto.StatusCode.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 code = 2;
 * @return {number}
 */
proto.StatusCode.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.StatusCode} returns this
 */
proto.StatusCode.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.DeviceType = {
  MTFC_AC: 0,
  MTFC_DC_24: 1,
  MTFC_DC_36: 2,
  MTFC_DC_48: 3,
  MTFC_SOLAR: 4,
  MTFC_SMS_CONTROLLER: 5
};

/**
 * @enum {number}
 */
proto.AccountRole = {
  NONE: 0,
  ADMIN: 1,
  OPERATOR: 2
};

/**
 * @enum {number}
 */
proto.ConnectionState = {
  CONNECTION_OFFLINE: 0,
  CONNECTION_ONLINE: 1
};

/**
 * @enum {number}
 */
proto.MTFCMainWorkState = {
  MTFC_STATE_OFF: 0,
  MTFC_STATE_AUTO: 1,
  MTFC_STATE_MANUAL: 2,
  MTFC_STATE_RAILWAY: 3,
  MTFC_STATE_WALKING: 4
};

/**
 * @enum {number}
 */
proto.PinSwitchProfile = {
  PIN_SWITCH_ONOFF: 0,
  PIN_SWITCH_RAILWAY: 1,
  PIN_SWITCH_WALKING: 2,
  PIN_SWITCH_T1: 3,
  PIN_SWITCH_T2: 4,
  PIN_SWITCH_T3: 5,
  PIN_SWITCH_T4: 6,
  PIN_SWITCH_OP: 7
};

/**
 * @enum {number}
 */
proto.PinOutProfile = {
  PIN_GREEN: 0,
  PIN_YELLOW: 1,
  PIN_RED: 2,
  PIN_WALKING_GREEN: 3,
  PIN_WALKING_RED: 4,
  PIN_OPTION_1: 5,
  PIN_OPTION_2: 6,
  PIN_OPTION_3: 7
};

/**
 * @enum {number}
 */
proto.TrafficLightState = {
  TRAFFIC_LIGHT_STATE_NONE: 0,
  TRAFFIC_LIGHT_STATE_GREEN: 1,
  TRAFFIC_LIGHT_STATE_RED: 2,
  TRAFFIC_LIGHT_STATE_YELLOW: 3
};

/**
 * @enum {number}
 */
proto.WalkingState = {
  WALKING_STATE_OFF: 0,
  WALKING_STATE_GREEN: 1,
  WALKING_STATE_RED: 2
};

goog.object.extend(exports, proto);
