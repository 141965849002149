/* eslint-disable */
var MainProto = require('./protobuf/main_proto_pb');
var UserProto = require('./protobuf/user_proto_pb');

function CreateLoginMessage(userName, password, userId, stationId, isWeb = false) {
    var mainMessage = new proto.MainMessage();
    var userMessage = new proto.UserMessage();
    var userAuthMessage = new proto.AuthUserMessage();
    var loginRequest = new proto.UserLoginRequest();
    if (userName !== null && password !== null) {
        loginRequest.setUsername(userName);
        loginRequest.setPassword(password);
    } else if (userId !== null && stationId !== null) {
        loginRequest.setUserid(userId);
        loginRequest.setAssignedstationid(stationId);
    }
    loginRequest.setIsweb(isWeb);
    userAuthMessage.setUserloginrequest(loginRequest);
    userMessage.setAuthusermessage(userAuthMessage);
    mainMessage.setUsermessage(userMessage);

    return mainMessage.serializeBinary();
}

function CreateControlLaneUserMessage(deviceId, isPowerOn, laneIndex) {
    var mainMessage = new proto.MainMessage();
    var userMessage = new proto.UserMessage();
    var deviceUserMessage = new proto.DeviceUserMessage();
    var controlLane = new proto.ControlLaneUserRequest();
    controlLane.setDeviceid(deviceId);
    controlLane.setLaneindex(laneIndex);
    controlLane.setIspoweron(isPowerOn);
    deviceUserMessage.setControllaneuserrequest(controlLane);
    userMessage.setDeviceusermessage(deviceUserMessage);
    mainMessage.setUsermessage(userMessage);

    return mainMessage.serializeBinary();
}

function CreateGetStationUserRequest(stationId) {
    var mainMessage = new proto.MainMessage();
    var userMessage = new proto.UserMessage();
    var stationUserMessage = new proto.StationUserMessage();
    var getStationUserRequest = new proto.GetStationUserRequest();

    getStationUserRequest.setStationid(stationId);

    stationUserMessage.setGetstationuserrequest(getStationUserRequest);
    userMessage.setStationusermessage(stationUserMessage);
    mainMessage.setUsermessage(userMessage);

    return mainMessage.serializeBinary();
}

function CreateGetStationListUserRequest(projectId) {
    var mainMessage = new proto.MainMessage();
    var userMessage = new proto.UserMessage();
    var stationUserMessage = new proto.StationUserMessage();
    var getStationListUserRequest = new proto.GetStationListUserRequest();

    getStationListUserRequest.setProjectid(projectId)

    stationUserMessage.setGetstationlistuserrequest(getStationListUserRequest);
    userMessage.setStationusermessage(stationUserMessage);
    mainMessage.setUsermessage(userMessage);

    return mainMessage.serializeBinary();
}

function CreateRequireStreamUserRequest(deviceId, isEnable) {
    var mainMessage = new proto.MainMessage();
    var userMessage = new proto.UserMessage();
    var deviceUserMessage = new proto.DeviceUserMessage();

    var requireStreamUserRequest = new proto.RequireStreamUserRequest();
    requireStreamUserRequest.setDeviceid(deviceId);
    requireStreamUserRequest.setEnable(isEnable);
    deviceUserMessage.setRequirestreamuserrequest(requireStreamUserRequest);
    userMessage.setDeviceusermessage(deviceUserMessage);
    mainMessage.setUsermessage(userMessage);

    return mainMessage.serializeBinary();
}

function SetActiveTimeUserRequest(deviceId, beginHour, beginMinute, endHour, endMinute) {
    var mainMessage = new proto.MainMessage();
    var userMessage = new proto.UserMessage();
    var deviceUserMessage = new proto.DeviceUserMessage();

    var activeLamp = new proto.ActiveLamp();
    var begin = new proto.HMTime();
    begin.setHour(beginHour);
    begin.setMinute(beginMinute);
    activeLamp.setBegin(begin);

    var end = new proto.HMTime();
    end.setHour(endHour);
    end.setMinute(endMinute);
    activeLamp.setEnd(end);

    var setActiveTimeUserRequest = new proto.SetActiveTimeUserRequest();
    setActiveTimeUserRequest.setActivelamp(activeLamp);
    setActiveTimeUserRequest.setDeviceid(deviceId);
    deviceUserMessage.setSetactivetimeuserrequest(setActiveTimeUserRequest);
    userMessage.setDeviceusermessage(deviceUserMessage);
    mainMessage.setUsermessage(userMessage);

    return mainMessage.serializeBinary();
}

function CreateSetCycleConfigUserRequest(deviceId, numPhase, yellowTime, clearanceTime, greenTime) {
    var mainMessage = new proto.MainMessage();
    var userMessage = new proto.UserMessage();
    var deviceUserMessage = new proto.DeviceUserMessage();

    var setCycleConfigUserRequest = new proto.SetCycleConfigUserRequest();
    setCycleConfigUserRequest.setDeviceid(deviceId);
    setCycleConfigUserRequest.setClearancetime(clearanceTime);
    setCycleConfigUserRequest.setNumphase(numPhase);
    setCycleConfigUserRequest.setGreentimeList(greenTime);
    setCycleConfigUserRequest.setYellowtime(yellowTime);

    deviceUserMessage.setSetcycleconfiguserrequest(setCycleConfigUserRequest);
    userMessage.setDeviceusermessage(deviceUserMessage);
    mainMessage.setUsermessage(userMessage);

    return mainMessage.serializeBinary();
}

function createSyncTimeUserRequest(deviceId) {
    var mainMessage = new proto.MainMessage();
    var userMessage = new proto.UserMessage();
    var deviceUserMessage = new proto.DeviceUserMessage();

    var syncTimeUserRequest = new proto.SyncTimeDeviceRequest();

    syncTimeUserRequest.setDeviceid(deviceId);

    deviceUserMessage.setSynctimeuserrequest(syncTimeUserRequest);
    userMessage.setDeviceusermessage(deviceUserMessage);
    mainMessage.setUsermessage(userMessage);

    return mainMessage.serializeBinary();
}

function createGetDeviceUserRequest(hardwareId) {
    var mainMessage = new proto.MainMessage();
    var userMessage = new proto.UserMessage();
    var deviceUserMessage = new proto.DeviceUserMessage();

    var getDeviceUserRequest = new proto.GetDeviceUserRequest();

    getDeviceUserRequest.setHardwareid(hardwareId);

    deviceUserMessage.setGetdeviceuserrequest(getDeviceUserRequest);
    userMessage.setDeviceusermessage(deviceUserMessage);
    mainMessage.setUsermessage(userMessage);

    return mainMessage.serializeBinary();
}

function createAddStationUserRequest(stationName, hardwareId, stationLat, stationLong) {
    var mainMessage = new proto.MainMessage();
    var userMessage = new proto.UserMessage();
    var stationUserMessage = new proto.StationUserMessage();

    var addStationUserRequest = new proto.AddStationUserRequest();

    addStationUserRequest.setHardwareid(hardwareId);
    addStationUserRequest.setStationname(stationName);
    addStationUserRequest.setStationlat(stationLat);
    addStationUserRequest.setStationlong(stationLong);

    stationUserMessage.setAddstationuserrequest(addStationUserRequest);
    userMessage.setStationusermessage(stationUserMessage);
    mainMessage.setUsermessage(userMessage);

    return mainMessage.serializeBinary();
}

function createGetCoordinateUserRequest(hardwareId) {
    var mainMessage = new proto.MainMessage();
    var userMessage = new proto.UserMessage();
    var deviceUserMessage = new proto.DeviceUserMessage();

    var getCoordinateUserRequest = new proto.GetCoordinateUserRequest();

    getCoordinateUserRequest.setHardwareid(hardwareId);

    deviceUserMessage.setGetcoordinateuserrequest(getCoordinateUserRequest);
    userMessage.setDeviceusermessage(deviceUserMessage);
    mainMessage.setUsermessage(userMessage);

    return mainMessage.serializeBinary();
}

function createSetSignSettingUserRequest(stationId, setting, backgroundSrc) {
    var mainMessage = new proto.MainMessage();
    var userMessage = new proto.UserMessage();
    var stationUserMessage = new proto.StationUserMessage();

    console.log("back ground " + backgroundSrc)

    var setSignSettingUserRequest = new proto.SetSignSettingUserRequest();

    setSignSettingUserRequest.setStationid(stationId);
    let array = [];
    setting.forEach(i => {
        var signParam = new proto.SignParam();
        signParam.setChannel(i.railway.channel)
        signParam.setX(i.railway.x);
        signParam.setY(i.railway.y);
        array.push(signParam);
    });
    setSignSettingUserRequest.setChannelrailwayList(array);

    array = [];
    setting.forEach(i => {
        var signParam = new proto.SignParam();
        signParam.setChannel(i.walking.channel)
        signParam.setX(i.walking.x);
        signParam.setY(i.walking.y);
        array.push(signParam);
    });
    setSignSettingUserRequest.setChannelwalkingList(array);

    array = [];
    setting.forEach(i => {
        var signParam = new proto.SignParam();
        signParam.setChannel(i.turnLeft.channel)
        signParam.setX(i.turnLeft.x);
        signParam.setY(i.turnLeft.y);
        array.push(signParam);
    });
    setSignSettingUserRequest.setChannelturnleftList(array);

    array = [];
    setting.forEach(i => {
        var signParam = new proto.SignParam();
        signParam.setChannel(i.turnRight.channel)
        signParam.setX(i.turnRight.x);
        signParam.setY(i.turnRight.y);
        array.push(signParam);
    });
    setSignSettingUserRequest.setChannelturnrightList(array);

    array = [];
    setting.forEach(i => {
        var signParam = new proto.SignParam();
        signParam.setChannel(i.trafficLight.channel)
        signParam.setX(i.trafficLight.x);
        signParam.setY(i.trafficLight.y);
        array.push(signParam);
    });
    setSignSettingUserRequest.setChanneltrafficlightList(array);

    array = [];
    setting.forEach(i => {
        var signParam = new proto.SignParam();
        signParam.setChannel(i.countdown.channel)
        signParam.setX(i.countdown.x);
        signParam.setY(i.countdown.y);
        array.push(signParam);
    });
    setSignSettingUserRequest.setChannelcounterList(array);

    array = [];
    setting.forEach(i => {
        var signParam = new proto.SignParam();
        signParam.setChannel(i.goAhead.channel)
        signParam.setX(i.goAhead.x);
        signParam.setY(i.goAhead.y);
        array.push(signParam);
    });
    setSignSettingUserRequest.setChannelgoaheadList(array);

    array = [];
    setting.forEach(i => {
        var signParam = new proto.SignParam();
        signParam.setText(i.label.text)
        signParam.setX(i.label.x);
        signParam.setY(i.label.y);
        array.push(signParam);
    });
    setSignSettingUserRequest.setChannellabelList(array);

    if (backgroundSrc !== null) {
        setSignSettingUserRequest.setBackgroundsrc(backgroundSrc);
    }

    stationUserMessage.setSetsignsettinguserrequest(setSignSettingUserRequest);
    userMessage.setStationusermessage(stationUserMessage);
    mainMessage.setUsermessage(userMessage);

    return mainMessage.serializeBinary();
}

function getAccountsUserRequest(projectId) {
    var mainMessage = new proto.MainMessage();
    var userMessage = new proto.UserMessage();
    var accountUserMessage = new proto.AccountUserMessage();
    var getAccountsUserRequest = new proto.GetAccountsUserRequest();

    getAccountsUserRequest.setProjectid(projectId)

    accountUserMessage.setGetaccountsuserrequest(getAccountsUserRequest);
    userMessage.setAccountusermessage(accountUserMessage);
    mainMessage.setUsermessage(userMessage);

    return mainMessage.serializeBinary();
}

function deleteAccountUserRequest(userId) {
    var mainMessage = new proto.MainMessage();
    var userMessage = new proto.UserMessage();
    var accountUserMessage = new proto.AccountUserMessage();
    var deleteAccountUserRequest = new proto.DeleteAccountUserRequest();

    deleteAccountUserRequest.setUserid(userId)

    accountUserMessage.setDeleteaccountuserrequest(deleteAccountUserRequest);
    userMessage.setAccountusermessage(accountUserMessage);
    mainMessage.setUsermessage(userMessage);

    return mainMessage.serializeBinary();
}

function createNewAccountUserRequest(userName, password, role) {
    var mainMessage = new proto.MainMessage();
    var userMessage = new proto.UserMessage();
    var accountUserMessage = new proto.AccountUserMessage();
    var createNewAccountUserRequest = new proto.CreateNewAccountUserRequest();

    createNewAccountUserRequest.setUsername(userName);
    createNewAccountUserRequest.setPassword(password);
    createNewAccountUserRequest.setRole(role);

    accountUserMessage.setCreatenewaccountuserrequest(createNewAccountUserRequest);
    userMessage.setAccountusermessage(accountUserMessage);
    mainMessage.setUsermessage(userMessage);

    return mainMessage.serializeBinary();
}

function getAccessKeyUserRequest() {
    var mainMessage = new proto.MainMessage();
    var userMessage = new proto.UserMessage();
    var authUserMessage = new proto.AuthUserMessage();
    var getAccessTokenUserRequest = new proto.GetAccessTokenUserRequest();

    authUserMessage.setGetaccesstokenuserrequest(getAccessTokenUserRequest);
    userMessage.setAuthusermessage(authUserMessage);
    mainMessage.setUsermessage(userMessage);

    return mainMessage.serializeBinary();
}

function getLatestFirmwareUserRequest() {
    var mainMessage = new proto.MainMessage();
    var userMessage = new proto.UserMessage();
    var stationUserMessage = new proto.StationUserMessage();
    var getLatestFirmwareUserRequest = new proto.GetLatestFirmwareUserRequest();

    stationUserMessage.setGetlatestfirmwareuserrequest(getLatestFirmwareUserRequest);
    userMessage.setStationusermessage(stationUserMessage);
    mainMessage.setUsermessage(userMessage);

    return mainMessage.serializeBinary();
}

function getLogUserRequest(date) {
    var mainMessage = new proto.MainMessage();
    var userMessage = new proto.UserMessage();
    var stationUserMessage = new proto.StationUserMessage();
    var getLogFileUserRequest = new proto.GetLogFileUserRequest();

    getLogFileUserRequest.setDate(date);
    getLogFileUserRequest.setStationid("213");

    stationUserMessage.setGetlogfileuserrequest(getLogFileUserRequest);
    userMessage.setStationusermessage(stationUserMessage);
    mainMessage.setUsermessage(userMessage);

    return mainMessage.serializeBinary();
}

function changePasswordUserRequest(oldPassword, newPassword) {
    var mainMessage = new proto.MainMessage();
    var userMessage = new proto.UserMessage();
    var accountUserMessage = new proto.AccountUserMessage();
    var changePasswordUserRequest = new proto.ChangePasswordUserRequest();

    changePasswordUserRequest.setPassword(oldPassword);
    changePasswordUserRequest.setNewpassword(newPassword);

    accountUserMessage.setChangepassworduserrequest(changePasswordUserRequest);
    userMessage.setAccountusermessage(accountUserMessage);
    mainMessage.setUsermessage(userMessage);

    return mainMessage.serializeBinary();
}

function deleteStationUserRequest(stationId) {
    var mainMessage = new proto.MainMessage();
    var userMessage = new proto.UserMessage();
    var stationUserMessage = new proto.StationUserMessage();

    var deleteStationUserRequest = new proto.DeleteStationUserRequest();

    deleteStationUserRequest.setStationid(stationId);

    stationUserMessage.setDeletestationuserrequest(deleteStationUserRequest);
    userMessage.setStationusermessage(stationUserMessage);
    mainMessage.setUsermessage(userMessage);

    return mainMessage.serializeBinary();
}

function editNumberCard(stationId, nCard) {
    var mainMessage = new proto.MainMessage();
    var userMessage = new proto.UserMessage();
    var stationUserMessage = new proto.StationUserMessage();

    var editNumberCardRequest = new proto.EditNumberCardUserRequest();

    editNumberCardRequest.setStationid(stationId);
    editNumberCardRequest.setNcard(nCard);

    stationUserMessage.setEditnumbercarduserrequest(editNumberCardRequest);
    userMessage.setStationusermessage(stationUserMessage);
    mainMessage.setUsermessage(userMessage);

    return mainMessage.serializeBinary();
}

function updateErrorStatus(stationId, status) {
    var mainMessage = new proto.MainMessage();
    var userMessage = new proto.UserMessage();
    var stationUserMessage = new proto.StationUserMessage();

    var updateErrorHandlingUserRequest = new proto.UpdateErrorHandlingUserRequest();

    updateErrorHandlingUserRequest.setStationid(stationId);
    updateErrorHandlingUserRequest.setErrorstatus(status);

    stationUserMessage.setUpdateerrorhandlinguserrequest(updateErrorHandlingUserRequest);
    userMessage.setStationusermessage(stationUserMessage);
    mainMessage.setUsermessage(userMessage);

    return mainMessage.serializeBinary();
}

function updateAlertInfo(email, phone) {
    var mainMessage = new proto.MainMessage();
    var userMessage = new proto.UserMessage();
    var accountUserMessage = new proto.AccountUserMessage();

    var updateAlertInfoUserRequest = new proto.UpdateAlertInfoUserRequest();

    updateAlertInfoUserRequest.setEmail(email);
    updateAlertInfoUserRequest.setPhone(phone);

    accountUserMessage.setUpdatealertinfouserrequest(updateAlertInfoUserRequest);
    userMessage.setAccountusermessage(accountUserMessage);
    mainMessage.setUsermessage(userMessage);

    return mainMessage.serializeBinary();
}

function updateDiaryUserRequest(stationId, message) {
    var mainMessage = new proto.MainMessage();
    var userMessage = new proto.UserMessage();
    var stationUserMessage = new proto.StationUserMessage();

    var updateDiaryLogUserRequest = new proto.UpdateDiaryLogUserRequest();

    updateDiaryLogUserRequest.setStationid(stationId);
    updateDiaryLogUserRequest.setMessage(message);

    stationUserMessage.setUpdatediaryloguserrequest(updateDiaryLogUserRequest);
    userMessage.setStationusermessage(stationUserMessage);
    mainMessage.setUsermessage(userMessage);

    return mainMessage.serializeBinary();
}

function insertAccessoryUserRequest(stationId, name, model, madeBy, position, installedPosition, installedDate, state) {
    var mainMessage = new proto.MainMessage();
    var userMessage = new proto.UserMessage();
    var stationUserMessage = new proto.StationUserMessage();

    var insertAccessoryUserRequest = new proto.InsertAccessoryUserRequest();

    var accessory = new proto.Accessory();

    accessory.setStationid(stationId);
    accessory.setName(name);
    accessory.setModel(model);
    accessory.setPosition(position);
    accessory.setInstalledpos(installedPosition);
    accessory.setInstalleddate(installedDate);
    accessory.setMadeby(madeBy);
    accessory.setStatus(state);

    insertAccessoryUserRequest.setAccessory(accessory);

    stationUserMessage.setInsertaccessoryuserrequest(insertAccessoryUserRequest);
    userMessage.setStationusermessage(stationUserMessage);
    mainMessage.setUsermessage(userMessage);

    return mainMessage.serializeBinary();
}

function getAccessoriesUserRequest(stationId) {
    var mainMessage = new proto.MainMessage();
    var userMessage = new proto.UserMessage();
    var stationUserMessage = new proto.StationUserMessage();

    var getAccessoriesUserRequest = new proto.GetAccessoriesUserRequest();

    getAccessoriesUserRequest.setStationid(stationId);

    stationUserMessage.setGetaccessoriesuserrequest(getAccessoriesUserRequest);
    userMessage.setStationusermessage(stationUserMessage);
    mainMessage.setUsermessage(userMessage);

    return mainMessage.serializeBinary();
}

function deleteAccessoryUserRequest(stationId, accessoryId) {
    var mainMessage = new proto.MainMessage();
    var userMessage = new proto.UserMessage();
    var stationUserMessage = new proto.StationUserMessage();

    var deleteAccessoryUserRequest = new proto.DeleteAccessoryUserRequest();

    deleteAccessoryUserRequest.setStationid(stationId);
    deleteAccessoryUserRequest.setAccessoryid(accessoryId);

    stationUserMessage.setDeleteaccessoryuserrequest(deleteAccessoryUserRequest);
    userMessage.setStationusermessage(stationUserMessage);
    mainMessage.setUsermessage(userMessage);

    return mainMessage.serializeBinary();
}

function getScheduleUserRequest(stationId) {
    var mainMessage = new proto.MainMessage();
    var userMessage = new proto.UserMessage();
    var stationUserMessage = new proto.StationUserMessage();

    var getScheduleUserRequest = new proto.GetScheduleUserRequest();

    getScheduleUserRequest.setStationid(stationId);

    stationUserMessage.setGetscheduleuserrequest(getScheduleUserRequest);
    userMessage.setStationusermessage(stationUserMessage);
    mainMessage.setUsermessage(userMessage);

    return mainMessage.serializeBinary();
}

export function updateSchedule(stationId, schedule) {
    var mainMessage = new proto.MainMessage();
    var userMessage = new proto.UserMessage();
    var stationUserMessage = new proto.StationUserMessage();

    var updateScheduleUserRequest = new proto.UpdateScheduleUserRequest();

    updateScheduleUserRequest.setStationid(stationId);

    if (schedule.dateForm !== undefined && schedule.dateForm.length === 7) {
        var dayLampForm = new proto.DayLampForm();
        dayLampForm.setIndexList(schedule.dateForm);
        updateScheduleUserRequest.setDays(dayLampForm);
    }

    if (schedule.timeForm !== undefined && schedule.timeForm.length === 8) {
        var timeLampForm = new proto.TimeLampForm();
        let indexList = [];
        schedule.timeForm.forEach((item, index) => {
            var dayTimeLine = new proto.DayTimeLine();
            var selectPointList = [];
            if (item.oneTime.length === 8) {
                item.oneTime.forEach((point, i) => {
                    var selectPoint = new proto.OneTimeline();
                    var begin = new proto.HMTime();
                    if (point.isEnable === false || point.start === undefined || point.start === '') {
                        begin.setHour(255);
                        begin.setMinute(255);
                    } else {
                        let eleList = point.start.split(':');
                        if (eleList === null || eleList.length !== 2) {
                            begin.setHour(255);
                            begin.setMinute(255);
                        } else {
                            begin.setHour(parseInt(eleList[0]));
                            begin.setMinute(parseInt(eleList[1]));
                        }
                    }
                    selectPoint.setTBeginApply(begin);
                    if (point.isEnable === false || point.target > 32) {
                        selectPoint.setIndexCycleForm(255);
                    } else {
                        selectPoint.setIndexCycleForm(point.target);
                    }
                    selectPointList.push(selectPoint);
                })
                dayTimeLine.setSelectpointList(selectPointList);
            }
            indexList.push(dayTimeLine);
        });
        timeLampForm.setIndexList(indexList);
        updateScheduleUserRequest.setTimelampform(timeLampForm);
    }

    if (schedule.signalForm !== undefined && schedule.signalForm.length === 32) {
        var cycleLampForm = new proto.CycleLampForm();
        let indexList = [];
        schedule.signalForm.forEach((item, index) => {
            var oneCycleLampTime = new proto.OneCycleLampTime();
            oneCycleLampTime.setIsmodified(item.isModified);
            oneCycleLampTime.setNumSideUsed(item.nSide);
            oneCycleLampTime.setClearanceTimeCrossroads(item.clearanceTime);
            var sideList = [];
            for (let i = 0; i < item.greenTime.length; i++) {
                var oneSideLampTime = new proto.OneSideLampTime();
                if (i < item.nSide) {
                    oneSideLampTime.setTGreen(item.greenTime[i]);
                    oneSideLampTime.setTYellow(item.yellowTime);
                }
                sideList.push(oneSideLampTime);
            }
            oneCycleLampTime.setSideList(sideList);
            indexList.push(oneCycleLampTime);
        })
        cycleLampForm.setIndexList(indexList);
        updateScheduleUserRequest.setCycle(cycleLampForm);
    }

    stationUserMessage.setUpdatescheduleuserrequest(updateScheduleUserRequest);
    userMessage.setStationusermessage(stationUserMessage);
    mainMessage.setUsermessage(userMessage);

    return mainMessage.serializeBinary();
}

export {
    CreateLoginMessage,
    CreateControlLaneUserMessage,
    CreateGetStationUserRequest,
    CreateGetStationListUserRequest,
    CreateRequireStreamUserRequest,
    SetActiveTimeUserRequest,
    CreateSetCycleConfigUserRequest,
    createSyncTimeUserRequest,
    createGetDeviceUserRequest,
    createAddStationUserRequest,
    createGetCoordinateUserRequest,
    createSetSignSettingUserRequest,
    getAccountsUserRequest,
    deleteAccountUserRequest,
    createNewAccountUserRequest,
    getAccessKeyUserRequest,
    getLatestFirmwareUserRequest,
    getLogUserRequest,
    changePasswordUserRequest,
    deleteStationUserRequest,
    editNumberCard,
    updateErrorStatus,
    updateAlertInfo,
    updateDiaryUserRequest,
    insertAccessoryUserRequest,
    getAccessoriesUserRequest,
    deleteAccessoryUserRequest,
    getScheduleUserRequest
};