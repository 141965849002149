/* eslint-disable */
import React from "react";
import {w3cwebsocket as W3CWebSocket} from "websocket";
import * as ProtoCreator from './ProtoCreator';

import LoginPage from "./LoginPage/LoginPage";
import {DashboardPage, SIGN_SETTING_DEFAULT, STATION_VALUE_DEFAULT} from "./DashboardPage/DashboardPage";
import MapPage from './MapPage/MapPage';
import {ManagementPage} from "./ManagementPage/ManagementPage";
import {createNewAccountUserRequest} from "./ProtoCreator";
import { save } from 'save-file';
import { config } from "./Config";
import {convertScheduleModel} from "./DashboardPage/Schedule";
import {SCHEDULE_FORM} from "./DashboardPage/ScheduleSampleData";
import {forEach} from "react-bootstrap/ElementChildren";

let MainProto = require('./protobuf/main_proto_pb');

// const this.state.deviceId = '11EFBFBD3B65EFBFBDEFBFBD08EFBFBD';

// const DEV_MODE = false;
// const DEV_MODE = true;

// const HOST_WS = DEV_MODE ? 'ws://127.0.0.1:8443/websocket' : 'wss://dev.hue-traffic.sitech.com.vn/websocket';
// const HOST_HTTP = DEV_MODE ? 'http://127.0.0.1:8443' : 'https://dev.hue-traffic.sitech.com.vn';
// const HOST_URL = 'dev.hue-traffic.sitech.com.vn';

// const HOST_WS = 'wssHOST_URL

class AppMain extends React.Component {
  constructor(props) {
    super(props);
    this.userId = this.props.userId || null;
    this.stationId = this.props.stationId || null;
    this.state = {
      isLogged: false,
      stationState: {...STATION_VALUE_DEFAULT},
      stationList: [
        {
          id: '123',
          deviceId: '',
          name: 'Ngã tư Thủ Đức',
          lat: 16.46969985961914,
          log: 107.60099792480469,
          isOn: false,
          isOnline: false,
          activeBegin: '--:--',
          activeEnd: '--:--',
        },
        {
          id: '123',
          deviceId: '',
          name: 'Ngã tư Võ Thị Sáu',
          lat: 16.46969985961914,
          log: 107.60099792480469,
          isOn: false,
          isOnline: false,
          activeBegin: '--:--',
          activeEnd: '--:--',
        }
      ],
      deviceId: null,
      user: "Trần Thanh Lộc",
      addStationResult: {
        stationName: null,
        numPhase: null,
        status: null,
        cpuType: null,
        lat: null,
        log: null
      },
      originLat: null,
      originLong: null,
      rootUserId: null,
      isManagement: false,
      accountList: [],
      role: 1,
      logContent: "",
      mapKey: "",
      latestApp: 'Unknown',
      email: '',
      phone: '',
      diaryLog: '',
      schedule: {...SCHEDULE_FORM},
    };

    this.ws = null;
    this.projectId = null;
    this.accessToken = null;

    this.originLat = 0;
    this.originLong = 0;

    this.onSubmitLoginForm = this.onSubmitLoginForm.bind(this);
    this.onControlLane = this.onControlLane.bind(this);
    this.onSelectStation = this.onSelectStation.bind(this);
    this.callbackClose = this.callbackClose.bind(this);
    this.onSetActiveTime = this.onSetActiveTime.bind(this);
    this.onClickChangeSchedule = this.onClickChangeSchedule.bind(this);
    this.onLogout = this.onLogout.bind(this);
    this.onSyncTime = this.onSyncTime.bind(this);
    this.onCheckDevice = this.onCheckDevice.bind(this);
    this.onAddStation = this.onAddStation.bind(this);
    this.onGetCoordinate = this.onGetCoordinate.bind(this);
    this.submitSignSetting = this.submitSignSetting.bind(this);
    this.onChangeManagementMode = this.onChangeManagementMode.bind(this);
    this.onDeleteAccount = this.onDeleteAccount.bind(this);
    this.onCreateNewAccount = this.onCreateNewAccount.bind(this);
    this.uploadFirmware = this.uploadFirmware.bind(this);
    this.getLatestFirmwareVersion = this.getLatestFirmwareVersion.bind(this);
    this.getLog = this.getLog.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.deleteStation = this.deleteStation.bind(this);
    this.onUpdateApp = this.onUpdateApp.bind(this);
    this.changeNumberCard = this.changeNumberCard.bind(this);
    this.updateErrorStatus = this.updateErrorStatus.bind(this);
    this.changeAlertInfo = this.changeAlertInfo.bind(this);
    this.submitDiary = this.submitDiary.bind(this);
    this.addAccessory = this.addAccessory.bind(this);
    this.deleteAccessory = this.deleteAccessory.bind(this);
    this.onGetSchedule = this.onGetSchedule.bind(this);
    this.checkIfConnected = this.checkIfConnected.bind(this);

  }

// single websocket instance for the own application and constantly trying to reconnect.

  componentDidMount() {
    console.log("mode " + process.env.REACT_APP_MODE + ", " + process.env.NODE_ENV)
    this.setState(state => state.isLogged = false);
    this.connect();
  }

  notify(message) {
    if (process.env.REACT_APP_MODE !== 'web') {
      window.api.send('asynchronous-message', 'dialog', null, null, message);
    } else {
      alert(message);
    }
  }

  getStationList(projectId) {
    this.ws.send(ProtoCreator.CreateGetStationListUserRequest(projectId));
  }

  getStation(stationId) {
    this.ws.send(ProtoCreator.CreateGetStationUserRequest(stationId));
  }

  onCheckDevice(hardwareId) {
    this.ws.send(ProtoCreator.createGetDeviceUserRequest(hardwareId));
  }

  timeout = 250; // Initial timeout duration as a class variable

  getColorState(trafficSignState) {
    switch (trafficSignState) {
      case proto.TrafficLightState.TRAFFIC_LIGHT_STATE_GREEN:
        return 'green';
      case proto.TrafficLightState.TRAFFIC_LIGHT_STATE_YELLOW:
        return 'yellow';
      case proto.TrafficLightState.TRAFFIC_LIGHT_STATE_RED:
        return 'red';
    }
    return 'none';
  }

  /**
   * @function connect
   * This function establishes the connect with the websocket and also ensures constant reconnection if connection closes
   */
  connect = () => {
    this.ws = new WebSocket(config.HOST_WS);
    this.ws.binaryType = "arraybuffer";
    let that = this; // cache the this
    var connectInterval;

    // websocket onopen event listener
    this.ws.onopen = () => {
      this.setState(state => state.isLogged = false);
      // this.setState(state => state.stationState.isOnline = true);

      that.timeout = 250; // reset timer to 250 on open of websocket connection
      clearTimeout(connectInterval); // clear Interval on on open of websocket connection
      if (this.userId !== null) {
        this.ws.send(
          ProtoCreator.CreateLoginMessage(
            null,
            null,
            this.userId,
            this.stationId
          )
        );
      }
    };

    // websocket onclose event listener
    this.ws.onclose = e => {
      if (process.env.REACT_APP_MODE !== 'web' && this.props.userId !== null) {
        window.api.send('asynchronous-message', 'dialog', null, null, "Truy cập không khả dụng!");
        window.close();
      }
      this.setState(state => state.stationState.isOnline = false);
      console.log(
        `Socket is closed. Reconnect will be attempted in ${Math.min(
          10000 / 1000,
          (that.timeout + that.timeout) / 1000
        )} second.`,
        e.reason
      );

      that.timeout = that.timeout + that.timeout; //increment retry interval
      connectInterval = setTimeout(this.check, Math.min(10000, that.timeout)); //call check function after timeout
    };

    // websocket onerror event listener
    this.ws.onerror = err => {
      this.setState(state => state.isLogged = false);
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );

      this.ws.close();
    };

    this.ws.onmessage = evt => {
      // listen to data sent from the websocket server
      // const message = JSON.parse(evt.data)
      let mainMessage = proto.MainMessage.deserializeBinary(evt.data);
      console.log("has a mainMessage");
      if (mainMessage?.hasUsermessage()) {
        let userMessage = mainMessage.getUsermessage();
        if (userMessage.hasAuthusermessage()) {
          if (userMessage.getAuthusermessage().hasUserloginresponse()) {
            console.log("has UserLoginResponse");
            let userLoginResponse = userMessage.getAuthusermessage().getUserloginresponse();
            if (userLoginResponse.getStatuscode().getCode() === 0) {
              console.log("Đăng nhập thành công, projectId " + userLoginResponse.getProjectid());
              this.projectId = userLoginResponse.getProjectid();
              this.setState(state => {
                state.isLogged = true;
                state.rootUserId = userLoginResponse.getUserid();
                state.role = userLoginResponse.getRole();
                state.mapKey = userLoginResponse.getMapkey();
                state.projectId = userLoginResponse.getProjectid();
                state.email = userLoginResponse.getEmail();
                state.phone = userLoginResponse.getPhone();
                return state;
              });
              if (this.props.stationId !== null) {
                this.getStation(this.props.stationId);
              } else {
                this.getStationList(this.projectId);
              }
              // this.getStation("11EB8B196396BAA8B39934E12D8889AC");
            } else {
              // window.api.send('asynchronous-message', 'dialog', null, null, "Truy cập bị từ chối!");
              this.notify("Truy cập bị từ chối!");
              if (this.props.userId !== null) {
                window.close();
              }
            }
          } else if (userMessage.getAuthusermessage().hasGetaccesstokenuserresponse()) {
            console.log("hasGetaccesstokenuserresponse");
            let token = userMessage.getAuthusermessage().getGetaccesstokenuserresponse().getAccesstoken();
            console.log("token %s", token);
            this.accessToken = token;
          }
        }
        else if (userMessage.hasDeviceusermessage()) {
          if (userMessage.getDeviceusermessage().hasGetdeviceuserresponse()) {
            let response = userMessage.getDeviceusermessage().getGetdeviceuserresponse();
            console.log("has getGetdeviceuserresponse");
            console.log("lat: " + response.getDevice().getLat() + ", long: " + response.getDevice().getLog());
            if (response.getStatuscode().getCode() === 0 && response.getDevice() != null) {
              this.setState(state => {
                state.addStationResult = {
                  stationName: response.getDevice().getStationname() === '' ? null : response.getDevice().getStationname(),
                  numPhase: response.getDevice().getDevicetype(),
                  status: response.getDevice().getDevicestate()?.getConnectionstate() === proto.ConnectionState.CONNECTION_ONLINE ?
                    'Đang kết nối' : 'Mất kết nối',
                  cpuType: 'AC',
                  lat: response.getDevice().getLat(),
                  log: response.getDevice().getLog()
                }
                state.originLat = response.getDevice().getLat();
                state.originLong = response.getDevice().getLog();
                return state;
              });
            } else {
              this.setState(state => state.addStationResult = {
                status: 'Không tìm thấy',
                numPhase: null,
                stationName: null,
                cpuType: null,
              });
            }
          }
        }
        else if (userMessage.hasDeviceonchangeusermessage()) {
          console.log("Has a device onchange message");
          let deviceOnchangeUserMessage = userMessage.getDeviceonchangeusermessage();
          if (deviceOnchangeUserMessage.hasUpdatedeviceconnectionstate()) {
            let updateDeviceConnectionState = deviceOnchangeUserMessage.getUpdatedeviceconnectionstate();
            if (this.state.deviceId != null && this.state.deviceId !== updateDeviceConnectionState.getDeviceid()) {
              console.log("Not this session, ignore");
              return;
            }
            if (updateDeviceConnectionState.getConnectionstate() === proto.ConnectionState.CONNECTION_ONLINE) {
              console.log("device is online");
              this.setState(state => state.stationState.isOnline = true);
              // this.getStation("11EB8B196396BAA8B39934E12D8889AC");
              this.ws.send(ProtoCreator.CreateRequireStreamUserRequest(this.state.deviceId, true));
            } else {
              console.log("device is offline");
              this.setState(state => state.stationState.isOnline = false);
            }
          }
          else if (deviceOnchangeUserMessage.hasDevicestateonchange()) {
            let deviceStateOnChanged = deviceOnchangeUserMessage.getDevicestateonchange();
            if (this.state.deviceId != null && this.state.deviceId !== deviceStateOnChanged.getDeviceid()) {
              console.log("Not this session, ignore");
              return;
            }
            let data = deviceStateOnChanged.getUpdate1second();
            if (data == null) {
              return;
            }
            // console.log("lane list " + data.getLanestateList()?.length);
            // if (data.getLanestateList()?.length < 1) {
            //   return;
            // }

            let channelState = [];

            if (data.getChannelstateList() !== null) {
              data.getChannelstateList().forEach(item => {
                channelState.push({
                  index: item.getIndex(),
                  trafficLight: this.getColorState(item.getLightstate()),
                  mappingLight: this.getColorState(item.getMappingstate()),
                  walking: item.getWalkingstate() === proto.WalkingState.WALKING_STATE_RED ? 'red' : (proto.WalkingState.WALKING_STATE_GREEN ? 'green' : 'off'),
                  counter: item.getCounter(),
                })
              })
            }

            if (this.state.stationState != null) {
              this.setState((state) => {
                // state.stationState.timer.current = trafficState;
                state.stationState.timer.current = channelState;
                state.stationState.trainWayMode = data.getIsrailwaysignalenable() ? 1 : 0;
                state.stationState.walkingMode = data.getIswalkingsignalenable() ? 1 : 0;
                state.stationState.stationInfo.cpuTime = data.getCputime();
                state.stationState.stationInfo.type = data.getCputype();
                state.stationState.control.lane = data.getLaneactive();
                state.stationState.control.onOff = data.getCpuonoff();
                state.stationState.isOnline = true;
                state.stationState.outputSignal = data.getOutputSensorList()
                state.stationState.activeTimeBegin = data.getActivebegin();
                state.stationState.activeTimeEnd = data.getActiveend();
                if (data.getSidetimeList() !== null && data.getSidetimeList().length > 0) {
                  state.stationState.timer.setting = [
                    {
                      yellowTime: data.getSidetimeList()[0].getTYellow(),
                      greenTime: data.getSidetimeList()[0].getTGreen(),
                      redTime: data.getSidetimeList()[0].getTRed(),
                      clearanceTime: data.getClearancetime(),
                    },
                    {
                      yellowTime: data.getSidetimeList()[1].getTYellow(),
                      greenTime: data.getSidetimeList()[1].getTGreen(),
                      redTime: data.getSidetimeList()[1].getTRed(),
                      clearanceTime: data.getClearancetime(),
                    },
                    {
                      yellowTime: data.getSidetimeList()[0].getTYellow(),
                      greenTime: data.getSidetimeList()[0].getTGreen(),
                      redTime: data.getSidetimeList()[0].getTRed(),
                      clearanceTime: data.getClearancetime(),
                    },
                    {
                      yellowTime: data.getSidetimeList()[1].getTYellow(),
                      greenTime: data.getSidetimeList()[1].getTGreen(),
                      redTime: data.getSidetimeList()[1].getTRed(),
                      clearanceTime: data.getClearancetime(),
                    }
                  ];
                } else {
                  console.log("schedule was null " + STATION_VALUE_DEFAULT.timer.setting[0].clearanceTime);
                  state.stationState.timer.setting = [
                    {
                      yellowTime: -1,
                      greenTime: -1,
                      redTime: -1,
                      clearanceTime: -1,
                    },
                    {
                      yellowTime: -1,
                      greenTime: -1,
                      redTime: -1,
                      clearanceTime: -1,
                    },
                    {
                      yellowTime: -1,
                      greenTime: -1,
                      redTime: -1,
                      clearanceTime: -1,
                    },
                    {
                      yellowTime: -1,
                      greenTime: -1,
                      redTime: -1,
                      clearanceTime: -1,
                    }
                  ];
                }
                return state;
              });
            }
          }
          else if (deviceOnchangeUserMessage.hasLogupdate()) {
            if (this.state.deviceId != null && this.state.deviceId !== deviceOnchangeUserMessage.getLogupdate().getDeviceid()) {
              console.log("Not this session, ignore");
              return;
            }
            console.log("update log " + deviceOnchangeUserMessage.getLogupdate().getMessage());
            if (this.state.stationState != null) {
              this.setState(state => state.stationState.logMessage = deviceOnchangeUserMessage.getLogupdate().getMessage());
            }
          }
          else if (deviceOnchangeUserMessage.hasErrorstatususermessage()) {
            if (this.state.deviceId != null && this.state.deviceId !== deviceOnchangeUserMessage.getErrorstatususermessage().getDeviceid()) {
              console.log("Not this session, ignore");
              return;
            }
            let errorStatus = deviceOnchangeUserMessage.getErrorstatususermessage();
            console.log("Update error status %d: %s", errorStatus.getErrorstatus(), errorStatus.getNotelist());
            if (this.state.stationState != null) {
              this.setState(state =>{
                state.stationState.errorStatus = errorStatus.getErrorstatus();
                state.stationState.noteList = errorStatus.getNotelist().split(";");
                return state;
              });
            }
          }
          else if (deviceOnchangeUserMessage.hasUpdatepowerstateusermessage()) {
            if (this.state.deviceId != null && this.state.deviceId !== deviceOnchangeUserMessage.getUpdatepowerstateusermessage().getDeviceid()) {
              console.log("Not this session, ignore");
              return;
            }
            let updateState = deviceOnchangeUserMessage.getUpdatepowerstateusermessage();
            console.log("Update state %s: %d", updateState.getDeviceid(), updateState.getIson() ? 1 : 0);
            if (updateState.getIson() != null) {
              this.setState(state => {
                state.stationState.control.onOff = updateState.getIson();
                state.stationList.forEach(station => {
                  if (station.deviceId === deviceOnchangeUserMessage.getUpdatepowerstateusermessage().getDeviceid()) {
                    station.isOn = updateState.getIson();
                  }
                });
                return state;
              })
            }
          }
          else if (deviceOnchangeUserMessage.hasUpdatescheduleusermessage()) {
            if (this.state.deviceId != null && this.state.deviceId !== deviceOnchangeUserMessage.getUpdatescheduleusermessage().getDeviceid()) {
              console.log("Not this session, ignore");
              return;
            }
            let updateState = deviceOnchangeUserMessage.getUpdatescheduleusermessage();
            console.log("Update schedule %s: %s - %s", updateState.getDeviceid(), updateState.getActivebegin(), updateState.getActiveend());
            if (updateState.getActivebegin() != null && updateState.getActiveend() != null) {
              this.setState(state => {
                state.stationState.activeTimeBegin = updateState.getActivebegin();
                state.stationState.activeTimeEnd = updateState.getActiveend();
                return state;
              })
            }

          }
          else if (deviceOnchangeUserMessage.hasUpdatemodeusermessage()) {
            if (this.state.deviceId != null && this.state.deviceId !== deviceOnchangeUserMessage.getUpdatemodeusermessage().getDeviceid()) {
              console.log("Not this session, ignore");
              return;
            }
            let updateState = deviceOnchangeUserMessage.getUpdatemodeusermessage();
            console.log("Update mode %s: %s - %d", updateState.getDeviceid(), updateState.getMode());
            if (updateState.getMode() != null) {
              this.setState(state => {
                state.stationState.mode = updateState.getMode();
                return state;
              })
            }
          }
          else if (deviceOnchangeUserMessage.hasUpdatemeterdatausermessage()) {
            if (this.state.deviceId != null && this.state.deviceId !== deviceOnchangeUserMessage.getUpdatemeterdatausermessage().getDeviceid()) {
              console.log("Not this session, ignore");
              return;
            }
            let updateState = deviceOnchangeUserMessage.getUpdatemeterdatausermessage();
            console.log("Update meter %s: %s - %d", updateState.getDeviceid(), updateState.getP1());
            this.setState(state => {
              state.stationState.meterData.a1 = Math.abs(updateState.getA1());
              state.stationState.meterData.a2 = Math.abs(updateState.getA2());
              state.stationState.meterData.a3 = Math.abs(updateState.getA3());

              state.stationState.meterData.i1 = updateState.getI1();
              state.stationState.meterData.i2 = updateState.getI2();
              state.stationState.meterData.i3 = updateState.getI3();

              state.stationState.meterData.u1 = updateState.getV1();
              state.stationState.meterData.u2 = updateState.getV2();
              state.stationState.meterData.u3 = updateState.getV3();

              state.stationState.meterData.p1 = updateState.getP1();
              state.stationState.meterData.p2 = updateState.getP2();
              state.stationState.meterData.p3 = updateState.getP3();
              return state;
            })
          }
        }
        else if (userMessage.hasStationusermessage()) {
          console.log("hasStationusermessage");
          let stationUserMessage = userMessage.getStationusermessage();
          if (stationUserMessage.hasGetstationuserresponse()) {
            let stationResponse = stationUserMessage.getGetstationuserresponse();
            let station = stationResponse.getStation();
            if (station === undefined) {
              console.log("station was null");
              return;
            }
            if (station.getName() == null) {
              return;
            }
            console.log("station name " + station.getName());
            if (station?.getDevice()?.getDeviceid() !== null) {
              this.setState(state => {
                state.deviceId = station.getDevice().getDeviceid();
                return state;
              });
            }
            let setting = [];
            if (station.getLanelightconfigList() !== null) {
              station.getLanelightconfigList().forEach((item, index) => {
                setting.push({
                  countdown: {
                    x: item.getCountdown() && item.getCountdown().getX() !== 0 ? item.getCountdown().getX() : SIGN_SETTING_DEFAULT[index].countdown.x,
                    y: item.getCountdown() && item.getCountdown().getY() !== 0 ? item.getCountdown().getY() : SIGN_SETTING_DEFAULT[index].countdown.y,
                  },
                  trafficLight: {
                    channel: item.getTrafficlight()?.getChannel(),
                    x: item.getTrafficlight() && item.getTrafficlight().getX() !== 0 ? item.getTrafficlight().getX() : SIGN_SETTING_DEFAULT[index].trafficLight.x,
                    y: item.getTrafficlight() && item.getTrafficlight().getY() !== 0 ? item.getTrafficlight().getY() : SIGN_SETTING_DEFAULT[index].trafficLight.y,
                  },
                  walking: {
                    channel: item.getWalking()?.getChannel(),
                    x: item.getWalking() && item.getWalking().getX() !== 0 ? item.getWalking().getX() : SIGN_SETTING_DEFAULT[index].walking.x,
                    y: item.getWalking() && item.getWalking().getY() !== 0 ? item.getWalking().getY() : SIGN_SETTING_DEFAULT[index].walking.y,
                  },
                  railway: {
                    channel: item.getRailway()?.getChannel(),
                    x: item.getRailway() && item.getRailway().getX() !== 0 ? item.getRailway().getX() : SIGN_SETTING_DEFAULT[index].railway.x,
                    y: item.getRailway() && item.getRailway().getY() !== 0 ? item.getRailway().getY() : SIGN_SETTING_DEFAULT[index].railway.y,
                  },
                  turnLeft: {
                    channel: item.getTurnleft()?.getChannel(),
                    x: item.getTurnleft() && item.getTurnleft().getX() !== 0 ? item.getTurnleft().getX() : SIGN_SETTING_DEFAULT[index].turnLeft.x,
                    y: item.getTurnleft() && item.getTurnleft().getY() !== 0 ? item.getTurnleft().getY() : SIGN_SETTING_DEFAULT[index].turnLeft.y,
                  },
                  turnRight: {
                    channel: item.getTurnright()?.getChannel(),
                    x: item.getTurnright() && item.getTurnright().getX() !== 0 ? item.getTurnright().getX() : SIGN_SETTING_DEFAULT[index].turnRight.x,
                    y: item.getTurnright() && item.getTurnright().getY() !== 0 ? item.getTurnright().getY() : SIGN_SETTING_DEFAULT[index].turnRight.y,
                  },
                  goAhead: {
                    channel: item.getGoahead()?.getChannel(),
                    x: item.getGoahead() && item.getGoahead().getX() !== 0 ? item.getGoahead().getX() : SIGN_SETTING_DEFAULT[index].goAhead.x,
                    y: item.getGoahead() && item.getGoahead().getY() !== 0 ? item.getGoahead().getY() : SIGN_SETTING_DEFAULT[index].goAhead.y,
                  },
                  label: {
                    text: item.getLabel() && item.getLabel().getText() !== '' ? item.getLabel().getText() : SIGN_SETTING_DEFAULT[index].label.text,
                    x: item.getLabel() && item.getLabel().getX() !== 0 ? item.getLabel().getX() : SIGN_SETTING_DEFAULT[index].label.x,
                    y: item.getLabel() && item.getLabel().getY() !== 0 ? item.getLabel().getY() : SIGN_SETTING_DEFAULT[index].label.y,
                  }
                })
              })
            }
            console.log("Number of card " + station.getNumcard());
            this.setState(state => {
              state.stationState = {...STATION_VALUE_DEFAULT};
              state.stationState.stationId = station.getStationid()
              state.stationState.stationInfo.stationName = station.getName();
              state.stationState.stationInfo.type = '-';
              state.stationState.stationInfo.hardwareId = '-';
              state.stationState.stationInfo.numPhase = 0;
              state.stationState.stationInfo.nCard = station.getNumcard();
              state.stationState.stationInfo.signSetting = setting.length === 0 ? state.stationState.stationInfo.signSetting : setting;
              // state.stationState.stationInfo.signSetting = state.stationState.stationInfo.signSetting;
              let device = station.getDevice();
              if (device !== null) {
                state.stationState.stationInfo.type = device.getDevicetype();
                state.stationState.stationInfo.hardwareId = device.getHardwareid();
                state.stationState.stationInfo.numPhase = device.getNumphase();
              }
              state.stationState.stationInfo.backgroundSrc = station.getBackground();
              state.stationState.errorStatus = station.getErrorstatus();
              if (station.getErrorstatusnote() !== undefined) {
                state.stationState.noteList = station.getErrorstatusnote().split(';');
              }
              console.log("active time %s - %s", station.getActivebegin(), station.getActiveend());
              console.log("isOn: " + station.getIson());
              state.stationState.control.onOff = station.getIson();
              state.stationState.isOnline = station.getConnectionstate() === proto.ConnectionState.CONNECTION_ONLINE;
              state.stationState.activeTimeBegin = station.getActivebegin();
              state.stationState.activeTimeEnd = station.getActiveend();
              state.stationState.mode = station.getMode();
              state.stationState.chartData = {
                label: [],
                value: []
              }
              if (station.getMeteritemsList() != null) {
                station.getMeteritemsList().forEach(item => {
                  state.stationState.chartData.label.push(item.getLabel());
                  state.stationState.chartData.value.push(item.getData())
                })
              }
              state.stationState.meterData.a1 = Math.abs(station.getA1());
              state.stationState.meterData.a2 = Math.abs(station.getA2());
              state.stationState.meterData.a3 = Math.abs(station.getA3());

              state.stationState.meterData.i1 = station.getI1();
              state.stationState.meterData.i2 = station.getI2();
              state.stationState.meterData.i3 = station.getI3();

              state.stationState.meterData.u1 = station.getV1();
              state.stationState.meterData.u2 = station.getV2();
              state.stationState.meterData.u3 = station.getV3();

              state.stationState.meterData.p1 = station.getP1();
              state.stationState.meterData.p2 = station.getP2();
              state.stationState.meterData.p3 = station.getP3();

              state.stationState.firmware = station.getFirmware();
              return state;
            });
            this.ws.send(ProtoCreator.CreateRequireStreamUserRequest(this.state.deviceId, true));
          }
          else if (stationUserMessage.hasGetstationlistuserresponse()) {
            console.log("hasGetstationlistuserresponse");
            let getStationListUserResponse = stationUserMessage.getGetstationlistuserresponse();
            console.log("origin lat: " + getStationListUserResponse.getOriginlat() + ", long: " + getStationListUserResponse.getOriginlong());
            this.setState(state => {
              state.stationList = [];
              getStationListUserResponse.getStationsList().forEach(station => {
                console.log("station: " + station.getName() + ", hardwareId: " + station.getHardwareid())
                console.log("activeTime %s - %s", station.getActivebegin(), station.getActiveend())
                state.stationList.push({
                  id: station.getStationid(),
                  deviceId: station.getDeviceid(),
                  name: station.getName(),
                  lat: station.getStationlat(),
                  log: station.getStationlong(),
                  hardwareId: station.getHardwareid(),
                  firmware: station.getFirmware(),
                  createDate: station.getCreatedate(),
                  isOn: station.getIson(),
                  isOnline: station.getConnectionstate() === proto.ConnectionState.CONNECTION_ONLINE,
                  activeBegin: station.getActivebegin(),
                  activeEnd: station.getActiveend()
                })
              });
              state.originLat = getStationListUserResponse.getOriginlat();
              state.originLong = getStationListUserResponse.getOriginlong();
              return state;
            });
          }
          else if (stationUserMessage.hasAddstationuserresponse()) {
            console.log("hasAddstationuserresponse");
            alert(stationUserMessage.getAddstationuserresponse().getStatuscode().getMessage());
            this.getStationList(this.projectId)
          }
          else if (stationUserMessage.hasSetsignsettinguserresponse()) {
            console.log("hasSetsignsettinguserresponse");
            this.notify("Thiết lập giao diện giám sát thành công!");
          }
          else if (stationUserMessage.hasGetlatestfirmwareuserresponse()) {
            console.log("hasGetlatestfirmwareuserresponse");
            let fwVersion = stationUserMessage.getGetlatestfirmwareuserresponse().getLatestfirmware();
            let appVersion = stationUserMessage.getGetlatestfirmwareuserresponse().getLatestappversion();
            this.setState(state => {
              state.latestApp = appVersion === '' ? 'Unknown' : appVersion;
              state.latestFirmware = fwVersion === '' ? 'Unknown' : fwVersion;
              return state;
            })
          }
          else if (stationUserMessage.hasGetlogfileuserresponse()) {
            // console.log(stationUserMessage.getGetlogfileuserresponse().getLog());
            this.setState(state => state.logContent = stationUserMessage.getGetlogfileuserresponse().getLog());
          }
          else if (stationUserMessage.hasDeletestationuserresponse()) {
            this.notify("Xóa trạm: " + stationUserMessage.getDeletestationuserresponse().getStatuscode().getMessage());
            this.getStationList(this.projectId);
          }
          else if (stationUserMessage.hasEditnumbercarduserresponse()) {
            this.notify("Thay đổi số Card công suất: " + stationUserMessage.getEditnumbercarduserresponse()?.getStatuscode()?.getMessage());
            if (stationUserMessage.getEditnumbercarduserresponse().getStatuscode().getCode() === 0) {
              this.setState(state => {
                state.stationState.stationInfo.nCard = stationUserMessage.getEditnumbercarduserresponse().getNcard();
                return state;
              });
            }
          }
          else if (stationUserMessage.hasUpdatediaryloguserresponse()) {
            this.setState(state => {
              state.diaryLog = stationUserMessage.getUpdatediaryloguserresponse().getFulllog();
              return state;
            })
          }
          else if (stationUserMessage.hasInsertaccessoryuserresponse()){
            if (stationUserMessage.getInsertaccessoryuserresponse().getStatuscode().getCode() === 0) {
              this.notify("Thêm thiết bị tại trạm thành công");
              this.setState(state => state.stationState.accessories = stationUserMessage.getInsertaccessoryuserresponse().getAccessoriesList());
            } else {
              this.notify("Thêm thiết bị tại trạm thất bại");
            }
          }
          else if (stationUserMessage.hasGetaccessoriesuserresponse()) {
            if (stationUserMessage.getGetaccessoriesuserresponse().getStatuscode().getCode() === 0) {
              this.setState(state => state.stationState.accessories = stationUserMessage.getGetaccessoriesuserresponse().getAccessoriesList());
            }
          }
          else if (stationUserMessage.hasDeleteaccessoryuserresponse()) {
            if (stationUserMessage.getDeleteaccessoryuserresponse().getStatuscode().getCode() === 0) {
              this.notify("Xóa thiết bị tại trạm thành công");
              this.setState(state => state.stationState.accessories = stationUserMessage.getDeleteaccessoryuserresponse().getAccessoriesList());
            }
          }
          else if (stationUserMessage.hasGetscheduleuserresponse()) {
            console.log("hasGetscheduleuserresponse");
            let response = stationUserMessage.getGetscheduleuserresponse();
            if (response?.getStatuscode().getCode() === 0) {
              let convertedModel = convertScheduleModel(response);
              this.setState(state => state.schedule = convertedModel);
              console.log("cycle " + response?.getCycle().getIndexList()[0].getNumSideUsed());
            }
          }
        }
        else if (userMessage.hasAccountusermessage()) {
          let accountUserMessage = userMessage.getAccountusermessage();
          if (accountUserMessage?.hasGetaccountsuserresponse()) {
            let accountList = accountUserMessage.getGetaccountsuserresponse().getAccountsList();
            if (accountList === null || accountList.length === 0) {
              return;
            }
            let mapAccount = [];
            accountList.forEach(account => {
              mapAccount.push(
                {
                  userId: account.getUserid(),
                  userName: account.getUsername(),
                  role: account.getRole(),
                  createDate: account.getCreatedate(),
                }
              )
            });
            if (mapAccount.length > 0) {
              this.setState(state => state.accountList = mapAccount);
            }
          }
          else if (accountUserMessage.hasCreatenewaccountuserresponse()) {
            let createnewAccountUserResponse = accountUserMessage.getCreatenewaccountuserresponse();
            this.notify('Tạo tài khoản: ' + createnewAccountUserResponse.getStatuscode().getMessage());
            this.onGetAccounts();
          }
          else if (accountUserMessage.hasDeleteaccountuserresponse()) {
            let deleteAccountUserResponse = accountUserMessage.getDeleteaccountuserresponse();
            this.notify('Xóa tài khoản: ' + deleteAccountUserResponse.getStatuscode().getMessage());
            this.onGetAccounts();
          }
          else if (accountUserMessage.hasChangepassworduserresponse()) {
            this.notify('Đổi mật khẩu: ' + accountUserMessage.getChangepassworduserresponse().getStatuscode().getMessage())
          }
          else if (accountUserMessage.hasUpdatealertinfouserresponse()) {
            this.notify("Đổi thông tin tiếp nhận lỗi: " + accountUserMessage.getUpdatealertinfouserresponse().getStatuscode().getMessage())
            if (accountUserMessage.getUpdatealertinfouserresponse().getStatuscode().getCode() === 0) {
              this.setState(state => {
                state.email = accountUserMessage.getUpdatealertinfouserresponse().getEmail();
                state.phone = accountUserMessage.getUpdatealertinfouserresponse().getPhone();
                return state;
              })
            }
          }
        }
      }
    }
  };

  componentWillUnmount() {
    this.ws.close();
  }

  onSubmitLoginForm(userName, password, name) {
    this.setState(state => state.user = name + " (" + userName + ")");
    // window.api.send('asynchronous-message', 'dialog', null, null, "hello");
    if (this.ws) {
      this.ws.send(ProtoCreator.CreateLoginMessage(userName, password, null, null, process.env.REACT_APP_MODE === 'web'));
    }

    // alert(userName + " " + password);
  }

  onSetActiveTime(beginHour, beginMinute, endHour, endMinute) {
    this.ws.send(ProtoCreator.SetActiveTimeUserRequest(this.state.deviceId, beginHour, beginMinute, endHour, endMinute));
    /*this.setState((state) => {
      state.stationState.activeTimeBegin = beginHour + ":" + beginMinute;
      state.stationState.activeTimeEnd = endHour + ":" + endMinute;
      return state;
    });*/
  }

  onControlLane(isPowerOn, index, deviceId = null) {
    console.log("on submit control: " + isPowerOn + ", lane " + index);
    if (deviceId === null) {
      deviceId = this.state.deviceId;
    }
    let control = ProtoCreator.CreateControlLaneUserMessage(deviceId, isPowerOn, index);
    this.ws.send(control);
    /*this.setState((state) => {
      state.stationState.control.onOff = isPowerOn;
      return state;
    });*/
  }

  onSelectStation(stationId) {
    console.log("selected stationId " + stationId);
    this.getStation(stationId);
  }

  callbackClose() {
    console.log("callbackClose, close streaming");
    this.setState(state => {
      state.deviceId = null;
      state.stationState = {...STATION_VALUE_DEFAULT};
      return state;
    });
    this.ws.send(ProtoCreator.CreateRequireStreamUserRequest(this.state.deviceId, false));
    this.getStationList(this.projectId);
  }

  onClickChangeSchedule(greenTime, yellowTime, clearanceTime) {
    this.ws.send(ProtoCreator.CreateSetCycleConfigUserRequest(
      this.state.deviceId, 2, yellowTime, clearanceTime, greenTime)
    );
  }

  onLogout() {
    this.setState(state => state.isLogged = false);
    this.ws.close();
    this.check();
  }

  onSyncTime() {
    this.ws.send(ProtoCreator.createSyncTimeUserRequest(this.state.deviceId));
  }

  onAddStation(stationName, hardwareId, stationLat, stationLong) {
    this.ws.send(ProtoCreator.createAddStationUserRequest(stationName, hardwareId, stationLat, stationLong));
  }

  onGetCoordinate(hardwareId) {
    this.ws.send(ProtoCreator.createGetCoordinateUserRequest(hardwareId));
  }

  submitSignSetting(stationId, setting, backgroundSrc) {
    this.ws.send(ProtoCreator.createSetSignSettingUserRequest(stationId, setting, backgroundSrc));
  }

  onChangeManagementMode(isActive) {
    this.setState(state => state.isManagement = isActive);
    if (isActive) {
      this.onGetAccounts();
      this.getLatestFirmwareVersion();
      // this.getStationList(this.projectId);
      this.ws.send(ProtoCreator.getAccessKeyUserRequest());
    }
    this.getStationList(this.projectId);
  }

  onGetAccounts() {
    this.ws.send(ProtoCreator.getAccountsUserRequest(this.projectId));
  }

  onDeleteAccount(userId) {
    this.ws.send(ProtoCreator.deleteAccountUserRequest(userId));
  }

  onCreateNewAccount(userName, password, role) {
    this.ws.send(ProtoCreator.createNewAccountUserRequest(userName, password, role));
  }

  uploadFirmware(firmwareVersion, file) {
    if (this.accessToken === null) {
      this.notify("Mã truy cập không hợp lệ");
    }
    const formData = new FormData();

    formData.append('file', file);
    formData.append('fwVer', firmwareVersion);
    fetch(
      config.HOST_HTTP + "/api/v1/ota/upload",
      {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + this.accessToken
        },
        body: formData,
      }
    )
      .then((response) => {
        console.log("response " + response.status);
        if (response.status === 201) {
          this.notify("Upload phiên bản " + firmwareVersion + " thành công")
        }
        // response.status;
      })
      .finally(() => {
        this.getLatestFirmwareVersion();
      })
      .catch((error) => {
        console.error('Error:', error);
      })
    ;
  }

  onUpdateApp() {
    if (process.env.REACT_APP_MODE === 'web') {
      this.notify("Tính năng không hỗ trợ!")
      return;
    }

    if (this.accessToken === null) {
      this.notify("Mã truy cập không hợp lệ");
    }

    fetch(
      config.HOST_HTTP + "/api/v1/ota/app-check?version=" + config.version + "&os=" + config.os + "&projectId=" + this.projectId,
      {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + this.accessToken
        }
      }
    )
      .then((response) => {
        if (response.status === 200) {
          let fileName = response.headers.has('content-disposition') ? response.headers.get('content-disposition').split('filename=')[1] : 'app.exe';
          console.log("response " + fileName);
          response.blob().then(blob => {
            save(blob, fileName).then(() => {
              alert("[Cảnh báo]: Vui lòng cài đặt chương trình mới!");
              window.close();
            });
            // window.close();
          });
        }
        // response.blob();
        // response.status;
      })
      .finally(() => {
        // this.getLatestFirmwareVersion();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  getLatestFirmwareVersion() {
    this.ws.send(ProtoCreator.getLatestFirmwareUserRequest());
  }

  getLog(date) {
    this.ws.send(ProtoCreator.getLogUserRequest(date));
  }

  changePassword(oldPassword, newPassword) {
    this.ws.send(ProtoCreator.changePasswordUserRequest(oldPassword, newPassword));
  }

  deleteStation(stationId) {
    this.ws.send(ProtoCreator.deleteStationUserRequest(stationId));
  }

  changeNumberCard(stationId, nCard) {
    if (this.ws !== undefined && this.ws.readyState === WebSocket.CLOSED) {
      return;
    }
    this.ws.send(ProtoCreator.editNumberCard(stationId, nCard));
  }

  updateErrorStatus(stationId, status) {
    if (this.ws !== undefined && this.ws.readyState === WebSocket.CLOSED) {
      return;
    }
    this.ws.send(ProtoCreator.updateErrorStatus(stationId, status));
  }

  changeAlertInfo(email, phone) {
    if (this.ws !== undefined && this.ws.readyState === WebSocket.CLOSED) {
      return;
    }
    this.ws.send(ProtoCreator.updateAlertInfo(email, phone));
  }

  submitDiary(stationId, message) {
    if (this.ws !== undefined && this.ws.readyState === WebSocket.CLOSED) {
      return;
    }
    this.ws.send(ProtoCreator.updateDiaryUserRequest(stationId, message));
    this.ws.send(ProtoCreator.getAccessoriesUserRequest(stationId));
  }

  addAccessory(stationId, name, model, madeBy, position, installedPosition, installedDate, state) {
    if (this.ws !== undefined && this.ws.readyState === WebSocket.CLOSED) {
      return;
    }
    this.ws.send(ProtoCreator.insertAccessoryUserRequest(stationId, name, model, madeBy, position, installedPosition, installedDate, state));
  }

  deleteAccessory(stationId, accessoryId) {
    if (this.ws !== undefined && this.ws.readyState === WebSocket.CLOSED) {
      return;
    }
    this.ws.send(ProtoCreator.deleteAccessoryUserRequest(stationId, accessoryId));
  }

  onGetSchedule(stationId) {
    if (this.ws !== undefined && this.ws.readyState === WebSocket.CLOSED) {
      return;
    }
    this.ws.send(ProtoCreator.getScheduleUserRequest(stationId));
  }

  onUpdateSchedule(stationId, schedule) {
    if (!this.checkIfConnected()) {
      return;
    }
    this.ws.send(ProtoCreator.updateSchedule(stationId, schedule))
  }

  checkIfConnected() {
    return this.ws !== undefined && this.ws.readyState !== WebSocket.CLOSED;
  }

  /**
   * utilited by the @function connect to check if the connection is close, if so attempts to reconnect
   */
  check = () => {
    if (!this.ws || this.ws.readyState === WebSocket.CLOSED) this.connect(); //check if websocket instance is closed, if so call `connect` function.
  };

  render() {
    let show = <div/>
    let managementPage = <ManagementPage
      onExit={() => this.onChangeManagementMode(false)}
      // onGetAccounts={this.onGetAccounts}
      accountList={this.state.accountList}
      onCreateNewAccount={this.onCreateNewAccount}
      onDeleteAccount={this.onDeleteAccount}
      onClose={() => this.onChangeManagementMode(false)}
      uploadFirmware={this.uploadFirmware}
      changeAlertInfo={this.changeAlertInfo}
      latestFirmware={this.state.latestFirmware}
      latestApp={this.state.latestApp}
      stationList={this.state.stationList}
      getLog={this.getLog}
      logContent={this.state.logContent}
      role={this.state.role}
      changePassword={this.changePassword}
      deleteStation={this.deleteStation}
      projectId={this.state.projectId}
      onUpdateApp={this.onUpdateApp}
      email={this.state.email}
      phone={this.state.phone}
    />
    let mapPage = <MapPage
      stationList={this.state.stationList}
      onSelectStation={this.onSelectStation}
      onLogout={this.onLogout}
      user={this.state.user}
      onCheckDevice={this.onCheckDevice}
      addStationResult={this.state.addStationResult}
      onAddStation={this.onAddStation}
      onGetCoordinate={this.onGetCoordinate}
      originLat={this.state.originLat}
      originLong={this.state.originLong}
      rootUserId={this.state.rootUserId}
      onManagement={() => this.onChangeManagementMode(true)}
      role={this.state.role}
      mapKey={this.state.mapKey}
      onControlLane={this.onControlLane}
    />
    let dashboardPage = <DashboardPage
      value={this.state.stationState}
      onControlLane={this.onControlLane}
      callbackClose={this.callbackClose}
      onChangeSchedule={this.onClickChangeSchedule}
      onSetActiveTime={this.onSetActiveTime}
      onSyncTime={this.onSyncTime}
      user={this.props.user || this.state.user}
      submitSignSetting={this.submitSignSetting}
      role={this.state.role}
      diaryLog={this.state.diaryLog}
      changeNumberCard={this.changeNumberCard}
      updateErrorStatus={this.updateErrorStatus}
      submitDiary={this.submitDiary}
      addAccessory={this.addAccessory}
      deleteAccessory={this.deleteAccessory}
      onGetSchedule={this.onGetSchedule}
      schedule={this.state.schedule}
      onUpdateSchedule={this.onUpdateSchedule.bind(this)}
    />;
    let loginPage = <LoginPage onSubmitLoginForm={this.onSubmitLoginForm}/>;
    if (this.state.isLogged) {
      if (this.state.isManagement === true) {
        show = managementPage;
      } else if (this.state.deviceId === null && this.props.userId === null) {
        show = mapPage
      } else {
        show = dashboardPage
      }
    } else {
        show = this.props.userId === null && loginPage;
    }
    return (
      <div>
        {show}
        {/*{loginPage}*/}
        {/*{mapPage}*/}
        {/*{dashboardPage}*/}
        {/*{managementPage}*/}
      </div>
    )
  }
}

export default AppMain;